import * as React from 'react'

const WIDTH = 600
const HEIGHT = 600

const DEAD_SHIP = -100
const ENEMY_DIMENSIONS: [number, number] = [40, 30]

const SHIP_DIMENSIONS: [number, number] = [70, 40]

const SHIP_Y = HEIGHT - SHIP_DIMENSIONS[1] - 20

const levels: Array<Array<[number, number, number]>> = [
  [
    [(WIDTH * 1) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 2) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 3) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 4) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 5) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 6) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 7) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 8) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 9) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
    [(WIDTH * 10) / 10 - ENEMY_DIMENSIONS[0], 200, 1],
  ],
  [
    [(WIDTH * 1) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 2) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 3) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 4) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 5) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 6) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 7) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 8) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 9) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
    [(WIDTH * 10) / 10 - ENEMY_DIMENSIONS[0], 200, 2],
  ],
  [
    [(WIDTH * 1) / 10 - ENEMY_DIMENSIONS[0], 200, 3],
    [(WIDTH * 2) / 10 - ENEMY_DIMENSIONS[0], 200, -3],
    [(WIDTH * 3) / 10 - ENEMY_DIMENSIONS[0], 200, 3],
    [(WIDTH * 4) / 10 - ENEMY_DIMENSIONS[0], 200, -3],
    [(WIDTH * 5) / 10 - ENEMY_DIMENSIONS[0], 200, 3],
    [(WIDTH * 6) / 10 - ENEMY_DIMENSIONS[0], 200, -3],
    [(WIDTH * 7) / 10 - ENEMY_DIMENSIONS[0], 200, 3],
    [(WIDTH * 8) / 10 - ENEMY_DIMENSIONS[0], 200, -3],
    [(WIDTH * 9) / 10 - ENEMY_DIMENSIONS[0], 200, 3],
    [(WIDTH * 10) / 10 - ENEMY_DIMENSIONS[0], 200, -3],
  ],
]

export const SpaceInvaders = () => {
  let context: CanvasRenderingContext2D
  let gameLoopInterval: NodeJS.Timeout
  let isRunning = true

  let gameOverTime = new Date().getTime()

  let gameState: {
    shipPosition: number
    shipProjectiles: Array<[number, number]>
    currentLevel: number
    enemies: Array<[number, number, number]>
    enemyProjectiles: Array<[number, number]>
  }

  function resetGame() {
    gameState = {
      shipPosition: WIDTH / 2,
      shipProjectiles: [],
      currentLevel: -1,
      enemies: [],
      enemyProjectiles: [],
    }
  }

  const keyboardState: { [key: string]: boolean } = {}

  let shotsFired = 0
  let enemiesKilled = 0

  function gameLoop() {
    input()
    logic()
    draw()

    if (gameState.enemies.length === 0) {
      gameState.currentLevel++
      gameState.enemies = levels[gameState.currentLevel] || []
    }

    const victory =
      gameState.enemies.length === 0 && gameState.currentLevel === levels.length
    const failure = gameState.shipPosition === DEAD_SHIP
    if (victory || failure) {
      clearInterval(gameLoopInterval)
      isRunning = false
      gameOverTime = new Date().getTime()
      context.fillStyle = `rgba(${victory ? 0 : 180},0,0,0.3)`
      context.fillRect(0, 0, WIDTH, HEIGHT)
      context.fillStyle = '#ccc'
      context.font = '35px monospace'
      context.fillText(
        victory ? 'You won!' : 'You lost.',
        WIDTH / 2 - 70,
        HEIGHT / 2,
      )
      context.font = '18px monospace'
      context.fillText(
        `Shots fired: ${shotsFired}`,
        WIDTH / 2 - 60,
        HEIGHT / 2 + 50,
      )
      context.fillText(
        `Enemies killed: ${enemiesKilled}`,
        WIDTH / 2 - 70,
        HEIGHT / 2 + 80,
      )
      context.fillText(
        `Accuracy: ${
          shotsFired === 0 ? 0 : ((enemiesKilled / shotsFired) * 100).toFixed(2)
        }%`,
        WIDTH / 2 - 60,
        HEIGHT / 2 + 110,
      )
    }
  }

  let lastShot = new Date().getTime()

  function input() {
    if (keyboardState.ArrowLeft) {
      if (gameState.shipPosition - 4 < 0) {
        gameState.shipPosition = 0
      } else {
        gameState.shipPosition -= 4
      }
    }
    if (keyboardState.ArrowRight) {
      if (gameState.shipPosition + 4 + SHIP_DIMENSIONS[0] > WIDTH) {
        gameState.shipPosition = WIDTH - SHIP_DIMENSIONS[0]
      } else {
        gameState.shipPosition += 4
      }
    }
    if (keyboardState.ArrowUp && new Date().getTime() - lastShot > 400) {
      lastShot = new Date().getTime()
      shotsFired++
      gameState.shipProjectiles.push([
        gameState.shipPosition + SHIP_DIMENSIONS[0] / 2,
        SHIP_Y,
      ])
    }
  }

  function logic() {
    gameState.shipProjectiles = gameState.shipProjectiles.map(([x, y]) => [
      x,
      y - 5,
    ])
    gameState.shipProjectiles = gameState.shipProjectiles.filter(
      ([x, y]) => y > 0,
    )

    gameState.enemyProjectiles = gameState.enemyProjectiles.map(([x, y]) => [
      x,
      y + 5,
    ])
    gameState.enemyProjectiles = gameState.enemyProjectiles.filter(
      ([x, y]) => y < HEIGHT,
    )

    const killProjectiles: Array<number> = []
    gameState.enemies = gameState.enemies.filter(([x, y]) => {
      return gameState.shipProjectiles.every(([xProj, yProj], index) => {
        const hitEnemy = isInside([xProj, yProj], [x, y, ...ENEMY_DIMENSIONS])
        if (hitEnemy) {
          enemiesKilled++
          killProjectiles.push(index)
        }
        return !hitEnemy
      })
    })

    if (
      gameState.enemyProjectiles.some(([x, y]) => {
        return isInside(
          [x, y],
          [gameState.shipPosition, SHIP_Y, ...SHIP_DIMENSIONS],
        )
      })
    ) {
      gameState.shipPosition = DEAD_SHIP
    }

    gameState.shipProjectiles = gameState.shipProjectiles.filter(
      (_, index) => !killProjectiles.includes(index),
    )

    gameState.enemies = gameState.enemies.map(([x, y, speed]) => {
      if (x + speed + ENEMY_DIMENSIONS[0] > WIDTH || x + speed < 0) {
        return [x, y + (ENEMY_DIMENSIONS[1] * 3) / 2, -speed * 1.05]
      }
      return [x + speed, y, speed]
    })

    gameState.enemies.forEach(([x, y]) => {
      if (
        isInside(
          [x, y],
          [gameState.shipPosition, SHIP_Y, ...SHIP_DIMENSIONS],
        ) ||
        isInside(
          [x + SHIP_DIMENSIONS[0], y],
          [gameState.shipPosition, SHIP_Y, ...SHIP_DIMENSIONS],
        ) ||
        isInside(
          [x + SHIP_DIMENSIONS[0], y + SHIP_DIMENSIONS[1]],
          [gameState.shipPosition, SHIP_Y, ...SHIP_DIMENSIONS],
        ) ||
        isInside(
          [x, y + SHIP_DIMENSIONS[1]],
          [gameState.shipPosition, SHIP_Y, ...SHIP_DIMENSIONS],
        )
      ) {
        gameState.shipPosition = DEAD_SHIP
      }
    })

    gameState.enemies.forEach(([x, y, speed]) => {
      if (Math.random() < 1 / (gameState.enemies.length * 20)) {
        gameState.enemyProjectiles.push([
          x + ENEMY_DIMENSIONS[0] / 2,
          y + ENEMY_DIMENSIONS[1] + 5,
        ])
      }
    })
  }

  function draw() {
    context.beginPath()
    context.fillStyle = '#181a3e'
    context.fillRect(0, 0, WIDTH, HEIGHT)

    context.fillStyle = '#8df9ed'
    drawShip()

    gameState.shipProjectiles.forEach(([x, y]) => {
      context.fillRect(x - 2, y - 2, 4, 4)
    })

    context.fillStyle = '#f94143'
    gameState.enemies.forEach(([x, y]) => drawEnemy(x, y))
    gameState.enemyProjectiles.forEach(([x, y]) => {
      context.fillRect(x - 2, y - 2, 4, 4)
    })

    context.fillStyle = '#8df9ed'
    context.font = '25px monospace'
    context.fillText(
      'Level ' + (gameState.currentLevel + 1),
      WIDTH / 2 - 50,
      40,
    )
  }

  function isInside(
    [x1, y1]: [number, number],
    [x2, y2, width, height]: [number, number, number, number],
  ) {
    return x1 > x2 && x1 < x2 + width && y1 > y2 && y1 < y2 + height
  }

  function drawEnemy(x: number, y: number) {
    context.moveTo(x + ENEMY_DIMENSIONS[0] / 2, y + ENEMY_DIMENSIONS[1])
    context.lineTo(
      x + (ENEMY_DIMENSIONS[0] * 3) / 4,
      y + (ENEMY_DIMENSIONS[1] * 2) / 3,
    )
    context.lineTo(x + (ENEMY_DIMENSIONS[0] * 3) / 4, y + ENEMY_DIMENSIONS[1])
    context.lineTo(x + ENEMY_DIMENSIONS[0], y + ENEMY_DIMENSIONS[1] / 2)
    context.lineTo(x + (ENEMY_DIMENSIONS[0] * 8) / 9, y)
    context.lineTo(
      x + (ENEMY_DIMENSIONS[0] * 3) / 4,
      y + ENEMY_DIMENSIONS[1] / 4,
    )
    context.lineTo(x + ENEMY_DIMENSIONS[0] / 2, y)
    context.lineTo(
      x + (ENEMY_DIMENSIONS[0] * 1) / 4,
      y + ENEMY_DIMENSIONS[1] / 4,
    )
    context.lineTo(x + (ENEMY_DIMENSIONS[0] * 1) / 9, y)
    context.lineTo(x, y + ENEMY_DIMENSIONS[1] / 2)
    context.lineTo(x + (ENEMY_DIMENSIONS[0] * 1) / 4, y + ENEMY_DIMENSIONS[1])
    context.lineTo(
      x + (ENEMY_DIMENSIONS[0] * 1) / 4,
      y + (ENEMY_DIMENSIONS[1] * 2) / 3,
    )
    context.closePath()
    context.fill()
  }

  function drawShip() {
    context.fillRect(
      gameState.shipPosition + SHIP_DIMENSIONS[0] / 2 - 5,
      SHIP_Y,
      10,
      SHIP_DIMENSIONS[1],
    )
    context.fillRect(
      gameState.shipPosition + SHIP_DIMENSIONS[0] / 2 - 20,
      SHIP_Y + 10,
      40,
      SHIP_DIMENSIONS[1] - 10,
    )
    context.fillRect(
      gameState.shipPosition,
      SHIP_Y + 30,
      SHIP_DIMENSIONS[0],
      SHIP_DIMENSIONS[1] - 35,
    )
    context.fillRect(
      gameState.shipPosition,
      SHIP_Y + 20,
      5,
      SHIP_DIMENSIONS[1] - 20,
    )
    context.fillRect(
      gameState.shipPosition + SHIP_DIMENSIONS[0] - 5,
      SHIP_Y + 20,
      5,
      SHIP_DIMENSIONS[1] - 20,
    )
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    context = canvasRef?.current?.getContext('2d') as CanvasRenderingContext2D

    const handleKeydown = (event: KeyboardEvent) => {
      event.preventDefault()
      keyboardState[event.key] = true

      if (!isRunning && new Date().getTime() - gameOverTime > 2000) {
        resetGame()
        isRunning = true
        gameLoopInterval = setInterval(gameLoop, 16)
      }
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      keyboardState[event.key] = false
    }
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyUp)

    resetGame()
    gameLoopInterval = setInterval(gameLoop, 16)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyUp)
      clearInterval(gameLoopInterval)
    }
  })

  return <canvas ref={canvasRef} width={WIDTH} height={HEIGHT} />
}
