import * as React from 'react'
import { BaseIcon } from './'

export const Square = (props: React.ComponentProps<typeof BaseIcon>) => (
  <BaseIcon {...props}>
    <rect
      fill="none"
      x="1"
      y="1"
      strokeWidth="2"
      width="10"
      height="10"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </BaseIcon>
)
