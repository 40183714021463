import * as React from 'react'
import styles from './styles.module.css'

type Props = React.ComponentProps<'div'> & {
  space?: 'all' | 'none' | 8 | 16
  orientation?: 'horizontal' | 'vertical'
  alignment?: 'center' | 'start' | 'bottom'
}

export const Spacer = ({
  space = 'all',
  orientation = 'horizontal',
  style,
  alignment = 'center',
  ...props
}: Props) => (
  <div
    style={{
      position: 'relative',
      display: 'flex',
      ...(orientation === 'vertical'
        ? {
            flexDirection: 'column',
            justifyContent: alignment,
            alignItems: alignment !== 'center' ? alignment : undefined,
          }
        : { flexDirection: 'row', alignItems: alignment }),
      ...style,
    }}
    className={styles[`space-${space}-${orientation}`]}
    {...props}
  />
)
