import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Button } from '../Button'

type Props = React.ComponentProps<typeof Button> & {
  path: string
}
export const NavigationButton = ({ path, ...props }: Props) => {
  const currentPath = useHistory().location.pathname
  return (
    <Link to={path}>
      <Button
        data-type={
          (path === '/'
          ? currentPath === path
          : currentPath.startsWith(path))
            ? 'primary'
            : undefined
        }
        {...props}
      />
    </Link>
  )
}
