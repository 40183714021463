import { CurriculumVitae } from './components/pages/CurriculumVitae'
import { VotePrompts } from './components/pages/VotePrompts'
import { Games } from './components/pages/Games'
import { Home } from './components/pages/Home'
import { PopulationDensityExperiment } from './components/pages/PopulationDensityExperiment'
import './styles.css'
import { Imprint } from './components/pages/Imprint'
import { DataProtection } from './components/pages/DataProtection'
import { Radweg } from './components/pages/Radweg'
import { Torii } from './components/pages/Torii'
import { Bushaltestellen } from './components/pages/Bushaltestellen'
import * as translations from './translations'
import { translate } from './utils/translations'

export const paths = [
  ['/', translate({ translations, path: ['home'] }), Home],
  ['/curriculumVitae', translate({ translations, path: ['curriculumVitae'] }), CurriculumVitae],
  ['/votePrompts', translate({ translations, path: ['votePrompts'] }), VotePrompts],
  ['/games', translate({ translations, path: ['games'] }), Games],
  [
    '/population_density_experiment',
    translate({
      translations,
      path: ['populationDensityExperiment'],
    }),
    PopulationDensityExperiment,
  ],
  ['/bh', 'Bushaltestellen', Bushaltestellen],
  ['/radweg', translate({ translations, path: ['radweg'] }), Radweg],
  ['/torii', translate({ translations, path: ['torii'] }), Torii],
  ['/data_protection', translate({ translations, path: ['dataProtection'] }), DataProtection],
  ['/imprint', translate({ translations, path: ['imprint'] }), Imprint],
] as const

export type Path = typeof paths[number][0]
