import * as React from 'react'
import { chunk } from 'lodash'
import { xhr } from '../../../xhr'
import { ContentBox } from '../../elementals/ContentBox'
import { toReadableFileSize } from './toReadableFileSize'
import { Page } from '../../elementals/Page'

type FileRepresentation = {
  size: number
  name: string
}

const File = ({ name, size }: FileRepresentation) => (
  <a href={`/filestorage/${name}`}>
    <ContentBox title={name}>{toReadableFileSize(size)}</ContentBox>
  </a>
)

export const FileStorage = () => {
  const [files, setFiles] = React.useState<Array<FileRepresentation>>([])
  React.useEffect(() => {
    xhr.get('/api/filestorage').then(({ data }) => {
      const files = chunk(
        (data as string).split('\n').filter(line => !!line),
        2,
      ).map(
        ([sizeStr, name]): FileRepresentation => ({
          size: Number.parseInt(sizeStr),
          name,
        }),
      )
      setFiles(files)
    })
  }, [])

  return (
    <Page>
      <h1>File storage</h1>
      <div style={{ display: 'flex' }}>
        {files.map(file => (
          <File key={file.name} {...file} />
        ))}
      </div>
    </Page>
  )
}
