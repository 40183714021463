import * as React from 'react'
import styles from './styles.module.css'

type Props = React.ComponentProps<'div'> & {
  text: React.ReactNode | undefined
  completion: number
}

export const Progress = ({ text, completion, style, ...props }: Props) => (
  <div
    style={{ flex: '1', position: 'relative', display: 'flex', ...style }}
    {...props}
  >
    <div className={styles.progress} style={{ width: '100%' }}>
      <div
        className={styles.progress}
        data-type="primary"
        style={{ width: `${completion}%` }}
      />
    </div>
    {text && (
      <div style={{ flex: 1 }} className={styles.progressContent}>
        {text}
      </div>
    )}
  </div>
)
