import * as React from 'react'
import styles from './styles.module.css'

type Props = {
  title: React.ReactNode
  children: React.ReactNode
} & Omit<React.ComponentProps<'div'>, 'title'>

export const ContentBox = ({ title, children, ...props }: Props) => (
  <div {...props}>
    <div className={styles.contentBoxTitle}>{title}</div>
    <div className={styles.contentBoxBody}>{children}</div>
    <div className={styles.contentBoxFooter} />
  </div>
)
