import * as React from 'react'

type ButtonProps = React.ComponentProps<'button'> & { 'data-type'?: 'primary' }

export const Button = ({ children, ...props }: ButtonProps) => (
  <button type="button" {...props}>
    {children}
  </button>
)

type LinkButtonProps = React.ComponentProps<'a'> & { 'data-type'?: 'primary' }

export const LinkButton = ({ children, ...props }: LinkButtonProps) => (
  <a type="button" {...props}>
    {children}
  </a>
)
