import * as React from 'react'
import { xhr } from '../../../xhr'
import { ContentBox } from '../../elementals/ContentBox'

const HEIGHT = 500
const WIDTH = 500

const STAGE_ELEMENT_SIZE = 50

const STAGE = {
  FLOOR: 0,
  SALAD: 1,
  CUT_SALAD: 2,
} as const

const STAGE_BLOCKING = {
  CUTTING_STATION: 100,
  HANDOUT: 200,
  SALAD_DISPENSER: 101,
} as const

type Item = 0 | 1 | 2 | 100 | 101 | 200

type GameState = {
  player: {
    position: [number, number]
    direction: [number, number]
    item: Item | null
  }
  currentLevel: number
  stage: {
    [key: string]: Array<Item>
  }
}

const gameState: GameState = {
  player: {
    position: [1, 1],
    direction: [-1, 0],
    item: null,
  },
  stage: {
    '0,0': [STAGE.FLOOR],
    '0,1': [STAGE.FLOOR],
    '0,2': [STAGE.FLOOR],
    '1,0': [STAGE.FLOOR],
    '1,1': [STAGE.FLOOR],
    '1,2': [STAGE.FLOOR],
    '2,0': [STAGE.FLOOR],
    '2,1': [STAGE.FLOOR],
    '2,2': [STAGE.FLOOR],
    '3,1': [STAGE.FLOOR],
    '4,1': [STAGE.FLOOR],
    '4,2': [STAGE.FLOOR, STAGE_BLOCKING.SALAD_DISPENSER],
    '5,0': [STAGE.FLOOR],
    '5,1': [STAGE.FLOOR],
    '5,2': [STAGE.FLOOR],
    '5,3': [STAGE.FLOOR],
    '5,4': [STAGE.FLOOR],
    '5,5': [STAGE.FLOOR],
    '6,0': [STAGE.FLOOR, STAGE_BLOCKING.CUTTING_STATION],
    '6,1': [STAGE.FLOOR],
    '6,2': [STAGE.FLOOR],
    '6,3': [STAGE.FLOOR],
    '6,4': [STAGE.FLOOR],
    '6,5': [STAGE.FLOOR],
    '6,6': [STAGE.FLOOR, STAGE_BLOCKING.HANDOUT],
    '7,0': [STAGE.FLOOR],
    '7,1': [STAGE.FLOOR],
    '7,2': [STAGE.FLOOR],
    '7,3': [STAGE.FLOOR],
    '7,4': [STAGE.FLOOR],
    '7,5': [STAGE.FLOOR],
  },
  currentLevel: 0,
}

const levels: Array<Array<[Item, number]>> = [
  [[STAGE.CUT_SALAD, 3]],
  [
    [STAGE.SALAD, 2],
    [STAGE.CUT_SALAD, 2],
  ],
]

const keyboardState: {
  [key: string]: boolean
} = {}

function gameLoop(
  context: CanvasRenderingContext2D,
  onEndGame: (passedTime: number) => void,
) {
  if (gameState.currentLevel < levels.length) {
    logic()
  }
  draw(context, onEndGame)
}

let lastPositionChange = 0
let lastPickup = 0
let gameStart = 0
let endTime = 0

function logic() {
  const [x, y] = gameState.player.position

  if (
    gameStart === 0 &&
    Object.keys(keyboardState).some(key => keyboardState[key])
  ) {
    gameStart = new Date().getTime()
  }

  let nextPosition: [number, number] = [x, y]
  if (keyboardState.ArrowLeft) {
    gameState.player.direction = [-1, 0]
    nextPosition = [x - 1, y]
  }
  if (keyboardState.ArrowUp) {
    gameState.player.direction = [0, -1]
    nextPosition = [x, y - 1]
  }
  if (keyboardState.ArrowRight) {
    gameState.player.direction = [1, 0]
    nextPosition = [x + 1, y]
  }
  if (keyboardState.ArrowDown) {
    gameState.player.direction = [0, 1]
    nextPosition = [x, y + 1]
  }
  if (keyboardState.z) {
    const [playerX, playerY] = gameState.player.position
    const [directionX, directionY] = gameState.player.direction

    const targetPosition = String([playerX + directionX, playerY + directionY])
    if (
      Object.keys(gameState.stage).includes(String(targetPosition)) &&
      new Date().getTime() - lastPickup > 100
    ) {
      if (gameState.player.item) {
        if (
          gameState.stage[targetPosition].includes(
            STAGE_BLOCKING.CUTTING_STATION,
          )
        ) {
          if (gameState.player.item === STAGE.SALAD) {
            gameState.stage[targetPosition].push(STAGE.CUT_SALAD)
          }
        } else if (
          gameState.stage[targetPosition].includes(STAGE_BLOCKING.HANDOUT)
        ) {
          levels[gameState.currentLevel] = levels[gameState.currentLevel]
            .map(([ingredient, count]): [Item, number] => {
              const newCount =
                ingredient === gameState.player.item ? count - 1 : count
              return [ingredient, newCount < 0 ? 0 : newCount]
            })
            .filter(([ingredient, count]) => count > 0)

          if (levels[gameState.currentLevel].length === 0) {
            gameState.currentLevel++
          }
        } else {
          gameState.stage[targetPosition].push(gameState.player.item)
        }
        gameState.player.item = null
      } else {
        if (
          gameState.stage[targetPosition].includes(
            STAGE_BLOCKING.SALAD_DISPENSER,
          )
        ) {
          gameState.player.item = STAGE.SALAD
        }
        if (
          gameState.stage[targetPosition].length > 1 &&
          !(Object.values(STAGE_BLOCKING) as Array<Item>).includes(
            gameState.stage[targetPosition][
              gameState.stage[targetPosition].length - 1
            ],
          )
        ) {
          const floorItem = gameState.stage[targetPosition].pop()
          if (floorItem !== undefined) {
            gameState.player.item = floorItem
          }
        }
      }
      lastPickup = new Date().getTime()
    }
  }

  if (
    (nextPosition[0] !== x || nextPosition[1] !== y) &&
    new Date().getTime() - lastPositionChange > 200 &&
    Object.keys(gameState.stage).includes(String(nextPosition)) &&
    gameState.stage[String(nextPosition)].every(
      item =>
        !Object.keys(STAGE_BLOCKING).some(
          stageKey =>
            STAGE_BLOCKING[stageKey as keyof typeof STAGE_BLOCKING] === item,
        ),
    )
  ) {
    gameState.player.position = nextPosition
    lastPositionChange = new Date().getTime()
  }
}

function draw(
  context: CanvasRenderingContext2D,
  onEndGame: (passedTime: number) => void,
) {
  context.fillStyle = '#222'
  context.fillRect(0, 0, WIDTH, HEIGHT)
  Object.keys(gameState.stage).forEach(coordinates => {
    const [x, y] = coordinates.split(',')
    const padding = 5

    const elementX = Number(x) * (STAGE_ELEMENT_SIZE + padding)
    const elementY = Number(y) * (STAGE_ELEMENT_SIZE + padding)

    drawElements(context, gameState.stage[coordinates], elementX, elementY)

    if (coordinates === String(gameState.player.position)) {
      context.fillStyle = '#a44'
      context.fillRect(
        elementX + STAGE_ELEMENT_SIZE / 4,
        elementY + STAGE_ELEMENT_SIZE / 4,
        STAGE_ELEMENT_SIZE / 2,
        STAGE_ELEMENT_SIZE / 2,
      )
      context.fillStyle = '#555'
      switch (String(gameState.player.direction)) {
        case String([-1, 0]):
          context.fillRect(
            elementX + STAGE_ELEMENT_SIZE / 4,
            elementY + (STAGE_ELEMENT_SIZE / 2 - STAGE_ELEMENT_SIZE / 10),
            STAGE_ELEMENT_SIZE / 5,
            STAGE_ELEMENT_SIZE / 5,
          )
          break
        case String([0, -1]):
          context.fillRect(
            elementX + (STAGE_ELEMENT_SIZE / 2 - STAGE_ELEMENT_SIZE / 10),
            elementY + STAGE_ELEMENT_SIZE / 4,
            STAGE_ELEMENT_SIZE / 5,
            STAGE_ELEMENT_SIZE / 5,
          )
          break
        case String([1, 0]):
          context.fillRect(
            elementX + STAGE_ELEMENT_SIZE / 2,
            elementY + (STAGE_ELEMENT_SIZE / 2 - STAGE_ELEMENT_SIZE / 10),
            STAGE_ELEMENT_SIZE / 5,
            STAGE_ELEMENT_SIZE / 5,
          )
          break
        case String([0, 1]):
          context.fillRect(
            elementX + (STAGE_ELEMENT_SIZE / 2 - STAGE_ELEMENT_SIZE / 10),
            elementY + STAGE_ELEMENT_SIZE / 2,
            STAGE_ELEMENT_SIZE / 5,
            STAGE_ELEMENT_SIZE / 5,
          )
          break
      }
      drawElements(context, [gameState.player.item], elementX, elementY)
    }
  })

  if (gameStart > 0) {
    drawTime(context)
  }

  if (gameState.currentLevel >= levels.length) {
    if (endTime === 0) {
      endTime = new Date().getTime()

      onEndGame(endTime - gameStart)
    }
    drawGameOver(context)
  } else {
    drawLevelGoal(context)
  }
}

function drawLevelGoal(context: CanvasRenderingContext2D) {
  context.fillStyle = 'rgba(255,190,255,0.1)'
  const height = WIDTH / 8
  const x = WIDTH / 4
  const y = HEIGHT - height
  context.fillRect(WIDTH / 4, HEIGHT - WIDTH / 8, WIDTH / 2, height)
  levels[gameState.currentLevel].forEach(
    ([ingredient, count], index, array) => {
      drawElements(context, [ingredient], x + STAGE_ELEMENT_SIZE * index, y)
      context.font = '12px monospace'
      context.fillStyle = '#aaa'
      context.fillText(
        String(count),
        x + STAGE_ELEMENT_SIZE * index + (STAGE_ELEMENT_SIZE * 4) / 5,
        y + (STAGE_ELEMENT_SIZE * 6) / 11,
      )
    },
  )
}

function drawGameOver(context: CanvasRenderingContext2D) {
  context.fillStyle = '#fff'
  const fontHeight = 24
  context.font = fontHeight + 'px monospace'
  const wonText = 'You won!'
  context.fillText(
    wonText,
    WIDTH / 2 - ((fontHeight / 2) * wonText.length) / 2,
    HEIGHT / 2,
  )
}

const mapping = {
  centisecond: 10,
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
}

const pad2 = (number: number): string =>
  String(number < 10 ? '0' + number : number)

function msToTime(ms: number) {
  const centiseconds = Math.floor((ms / mapping.centisecond) % 100)
  let unit = 'cs'
  let output = pad2(centiseconds)

  if (ms >= mapping.second) {
    const seconds = Math.floor((ms / mapping.second) % 60)
    unit = 's'
    output = pad2(seconds) + '.' + output
  }

  if (ms >= mapping.minute) {
    const minutes = Math.floor((ms / mapping.minute) % 60)
    unit = 'min'
    output = pad2(minutes) + ':' + output
  }

  if (ms >= mapping.hour) {
    const hours = Math.floor(ms / mapping.hour)
    unit = 'h'
    output = pad2(hours) + ':' + output
  }

  return output + unit
}

function drawTime(context: CanvasRenderingContext2D) {
  const timeDelta = (endTime || new Date().getTime()) - gameStart

  context.font = '20px monospace'
  context.fillText(msToTime(timeDelta), WIDTH / 32, HEIGHT - WIDTH / 16)
}

function drawElements(
  context: CanvasRenderingContext2D,
  items: Array<Item | null>,
  x: number,
  y: number,
) {
  items.forEach(item => {
    switch (item) {
      case STAGE_BLOCKING.SALAD_DISPENSER:
        context.fillStyle = '#999'
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 8,
          y + STAGE_ELEMENT_SIZE / 8,
          (STAGE_ELEMENT_SIZE * 3) / 4,
          (STAGE_ELEMENT_SIZE * 3) / 4,
        )
      case STAGE.SALAD:
        context.fillStyle = '#474'
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 3,
          y + STAGE_ELEMENT_SIZE / 3,
          STAGE_ELEMENT_SIZE / 3,
          STAGE_ELEMENT_SIZE / 3,
        )
        break
      case STAGE.CUT_SALAD:
        context.fillStyle = '#494'
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 3,
          y + STAGE_ELEMENT_SIZE / 3,
          STAGE_ELEMENT_SIZE / 10,
          STAGE_ELEMENT_SIZE / 3,
        )
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 3 + STAGE_ELEMENT_SIZE / 8,
          y + STAGE_ELEMENT_SIZE / 3,
          STAGE_ELEMENT_SIZE / 10,
          STAGE_ELEMENT_SIZE / 3,
        )
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 3 + STAGE_ELEMENT_SIZE / 4,
          y + STAGE_ELEMENT_SIZE / 3,
          STAGE_ELEMENT_SIZE / 10,
          STAGE_ELEMENT_SIZE / 3,
        )
        break
      case STAGE.FLOOR:
        context.fillStyle = '#555'
        context.fillRect(x, y, STAGE_ELEMENT_SIZE, STAGE_ELEMENT_SIZE)
        break
      case STAGE_BLOCKING.HANDOUT:
        context.fillStyle = '#4a4'
        context.beginPath()
        context.moveTo(
          x + (STAGE_ELEMENT_SIZE * 5) / 8,
          y + (STAGE_ELEMENT_SIZE * 1) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 3) / 8,
          y + (STAGE_ELEMENT_SIZE * 1) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 3) / 8,
          y + (STAGE_ELEMENT_SIZE * 5) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 2) / 8,
          y + (STAGE_ELEMENT_SIZE * 5) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 4) / 8,
          y + (STAGE_ELEMENT_SIZE * 7) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 6) / 8,
          y + (STAGE_ELEMENT_SIZE * 5) / 8,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 5) / 8,
          y + (STAGE_ELEMENT_SIZE * 5) / 8,
        )
        context.fill()
        break
      case STAGE_BLOCKING.CUTTING_STATION:
        context.fillStyle = '#999'
        context.fillRect(
          x + STAGE_ELEMENT_SIZE / 8,
          y + STAGE_ELEMENT_SIZE / 8,
          (STAGE_ELEMENT_SIZE * 3) / 4,
          (STAGE_ELEMENT_SIZE * 3) / 4,
        )
        context.fillStyle = '#222'
        context.beginPath()
        context.moveTo(
          x + (STAGE_ELEMENT_SIZE * 12) / 16,
          y + (STAGE_ELEMENT_SIZE * 5) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 9) / 16,
          y + (STAGE_ELEMENT_SIZE * 8) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 8) / 16,
          y + (STAGE_ELEMENT_SIZE * 7) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 11) / 16,
          y + (STAGE_ELEMENT_SIZE * 4) / 16,
        )
        context.fill()
        context.beginPath()
        context.fillStyle = '#ccc'
        context.moveTo(
          x + (STAGE_ELEMENT_SIZE * 8) / 16,
          y + (STAGE_ELEMENT_SIZE * 7) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 3) / 16,
          y + (STAGE_ELEMENT_SIZE * 12) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 7) / 16,
          y + (STAGE_ELEMENT_SIZE * 11) / 16,
        )
        context.lineTo(
          x + (STAGE_ELEMENT_SIZE * 10) / 16,
          y + (STAGE_ELEMENT_SIZE * 9) / 16,
        )
        context.fill()
        break
    }
  })
  const ingredientCount = items.filter(item => item !== STAGE.FLOOR).length
  if (ingredientCount > 1) {
    context.fillStyle = '#222'
    context.font = '12px monospace'
    context.fillText(String(ingredientCount), x + 5, y + STAGE_ELEMENT_SIZE / 3)
  }
}

export const Overcooked = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  const [highscores, setHighscores] = React.useState([])

  React.useEffect(() => {
    const context = canvasRef?.current?.getContext(
      '2d',
    ) as CanvasRenderingContext2D

    const element = document.createElement('canvas')

    document.addEventListener('keydown', event => {
      event.preventDefault()
      keyboardState[event.key] = true
    })
    document.addEventListener('keyup', event => {
      keyboardState[event.key] = false
    })

    element.height = HEIGHT
    element.width = WIDTH

    context.beginPath()
    context.fillStyle = '#222'
    context.fillRect(0, 0, WIDTH, HEIGHT)

    setInterval(
      () =>
        gameLoop(context, () => {
          xhr
            .post('/api/games/overcooked/highscore', endTime - gameStart)
            .then(({ data }) => {
              setHighscores(data)
            })
        }),
      16,
    )

    xhr.get('/api/games/overcooked/highscore').then(({ data }) => {
      setHighscores(data)
    })
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <canvas ref={canvasRef} width={WIDTH} height={HEIGHT} />
        {highscores.length > 0 ? (
          <ContentBox title="Highscores">
            {highscores.flatMap((highscore, index) =>
              highscore
                ? [
                    <div key={index}>
                      {index + 1}.&nbsp;{msToTime(highscore)}
                    </div>,
                  ]
                : [],
            )}
          </ContentBox>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
