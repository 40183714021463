import * as React from 'react'
import { Link } from 'react-router-dom'
import { sumBy } from 'lodash'
import { xhr } from '../../../xhr'
import { VotePrompt } from './types'
import { formatConvenient, formatExact, toDate } from '../../../utils/date'
import { Spacer } from '../../elementals/Spacer'
import { Progress } from '../../elementals/Progress'
import { ContentBox } from '../../elementals/ContentBox'
import { Radio } from '../../elementals/Chooser'
import { Circle } from '../../elementals/Icons/Circle'
import { CircleDot } from '../../elementals/Icons/CircleDot'
import { Close } from '../../elementals/Icons/Close'
import { Button } from '../../elementals/Button'
import { Badge } from '../../elementals/Badge'
import { Text } from '../../elementals/Text'
import * as translations from './translations'
import { translate } from '../../../utils/translations'

type Props = VotePrompt & {
  setPrompts: React.Dispatch<React.SetStateAction<Array<VotePrompt>>>
}
export const Prompt = ({
  prompt_id,
  created_by,
  created_at,
  closed_at,
  prompt_text,
  answers,
  isCreator,
  isPublic,
  setPrompts,
}: Props) => {
  const isPromptInvalidated =
    closed_at != null && new Date(closed_at).getTime() < Date.now()
  const hasVotedPrompt = answers.some(({ hasVoted }) => hasVoted)
  const maxVotes = sumBy(answers, ({ votes }) => votes)

  const isClosedForVoting = isPromptInvalidated || hasVotedPrompt

  return (
    <ContentBox
      title={
        <Spacer space="all" style={{ flexWrap: 'wrap' }}>
          <Spacer space={8} style={{ flexWrap: 'wrap' }}>
            {isCreator && (
              <>
                {!isPublic && (
                  <Badge data-type="primary">
                    {translate({
                      translations,
                      path: ['prompt', 'private'],
                    })}
                  </Badge>
                )}
                <Badge>
                  {translate({
                    translations,
                    path: ['prompt', 'yours'],
                  })}
                </Badge>
              </>
            )}
            <div style={{ whiteSpace: 'nowrap' }}>{created_by}</div>
          </Spacer>
          <Spacer space={8}>
            <Link to={`?${prompt_id}`}>
              {translate({
                translations,
                path: ['prompt', 'promptedOn'],
                passed: (
                  <Text title={formatExact(toDate(created_at))}>
                    {formatConvenient(toDate(created_at))}
                  </Text>
                ),
              })}
            </Link>
            {isCreator && (
              <Button
                onClick={() =>
                  window.confirm(
                    translate({
                      translations,
                      path: ['prompt', 'deleteConfirm'],
                    }),
                  ) &&
                  xhr
                    .delete(`/api/vote?${prompt_id}`)
                    .then(() =>
                      setPrompts(prev =>
                        prev.filter(current => current.prompt_id !== prompt_id),
                      ),
                    )
                }
              >
                <Close />
              </Button>
            )}
          </Spacer>
        </Spacer>
      }
    >
      <Spacer orientation="vertical" space={8}>
        <div>
          <span style={{ fontSize: '1.25em' }}>{prompt_text}</span>
        </div>
        <Spacer space={8} orientation="vertical">
          {answers.map(({ id, text, votes, hasVoted }) => {
            const voteShare = maxVotes === 0 ? 0 : (votes / maxVotes) * 100

            return (
              <div
                key={id}
                title={translate({
                  translations,
                  path: ['prompt', 'answerVotes'],
                  passed: votes,
                })}
              >
                <Radio
                  disabled={isClosedForVoting}
                  icon={null}
                  name={prompt_id + 'answers'}
                  value={id}
                  checked={hasVoted}
                  onChange={event => {
                    if (!hasVotedPrompt) {
                      const answerId = event.target.value

                      xhr
                        .post(
                          '/api/voteAnswer',
                          JSON.stringify({
                            answerId,
                            promptId: prompt_id,
                          }),
                        )
                        .then(({ data }) =>
                          setPrompts(prev => {
                            const promptIndex = prev.findIndex(
                              prompt => prompt.prompt_id === prompt_id,
                            )
                            return prev
                              .slice(0, promptIndex)
                              .concat(data[0])
                              .concat(prev.slice(promptIndex + 1))
                          }),
                        )
                    }
                  }}
                >
                  <Progress
                    completion={isClosedForVoting ? voteShare : 0}
                    text={
                      <Spacer space={8}>
                        {isClosedForVoting ? null : hasVoted ? (
                          <CircleDot />
                        ) : (
                          <Circle />
                        )}
                        <Spacer space="all">
                          <div>{text}</div>
                          {isClosedForVoting && (
                            <div>
                              {voteShare.toFixed(1)}%&nbsp;({votes})
                            </div>
                          )}
                        </Spacer>
                      </Spacer>
                    }
                  />
                </Radio>
              </div>
            )
          })}
        </Spacer>
        <Spacer>
          <div />
          {closed_at && (
            <div>
              {translate({
                translations,
                path: [
                  'prompt',
                  isPromptInvalidated
                    ? 'invalidationPast'
                    : 'invalidationFuture',
                ],
                passed: (
                  <Text title={formatExact(toDate(closed_at))}>
                    {formatConvenient(toDate(closed_at))}
                  </Text>
                ),
              })}
            </div>
          )}
        </Spacer>
      </Spacer>
    </ContentBox>
  )
}
