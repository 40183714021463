import * as React from 'react'
import { Route } from 'react-router-dom'
import dbLogo from './logo.min.svg'
import styles from './curriculumVitae.module.css'
import * as translations from './translations'
import * as imprintTranslations from '../Imprint/translations'
import * as appTranslations from '../../../translations'
import { translate } from '../../../utils/translations'
import { Page } from '../../elementals/Page'

export const CurriculumVitae = () => (
  <Page>
    <div className={styles.curriculumVitae}>
      <div style={{ display: 'flex', height: '10em', alignItems: 'center' }}>
        <Route
          exact
          path="/curriculumVitae"
          render={() => (
            <>
              <img
                src={dbLogo}
                alt="danielbartsch logo"
                style={{ maxHeight: '11em', paddingRight: '1em' }}
              />
              <div style={{ display: 'inline-block' }}>
                <h1>
                  {translate({
                    translations: appTranslations,
                    path: ['curriculumVitae'],
                  })}
                </h1>
                <h2>
                  {translate({
                    translations: imprintTranslations,
                    path: ['name'],
                  })}
                </h2>
              </div>
            </>
          )}
        />
      </div>
      <table cellSpacing="0">
        <tbody>
          <tr>
            <td>{translate({ translations, path: ['contactInformation'] })}</td>
            <td>
              <a href="tel:+4367761991478">+43 677 6199 1478</a>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="mailto:daniel.bartsch@live.at">daniel.bartsch@live.at</a>
            </td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['birthDate'] })}</td>
            <td>
              1996-01-09
              <br />
              3430 Tulln
            </td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['citizenship'] })}</td>
            <td>
              {translate({
                translations: imprintTranslations,
                path: ['country'],
              })}
            </td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['maritalStatus'] })}</td>
            <td>{translate({ translations, path: ['maritalStatusValue'] })}</td>
          </tr>
          <tr>
            <td>
              {translate({
                translations: imprintTranslations,
                path: ['address'],
              })}
            </td>
            <td>
              <a href="https://www.openstreetmap.org/?mlat=48.2887&mlon=15.9501#map=11/48.2887/15.9501&layers=H">
                3451 Atzelsdorf
                <br />
                Wiesengasse 9
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table cellSpacing="0" className={styles.oddRowsColored}>
        <tbody>
          <tr>
            <td>{translate({ translations, path: ['jobs'] })}</td>
            <td>
              {translate({
                translations,
                path: ['since'],
                passed: '2022.04',
              })}
            </td>
            <td>
              <a href="https://www.adverity.com/">Adverity GmbH</a>, 1010{' '}
              {translate({ translations, path: ['vienna'] })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {translate({
                translations,
                path: ['fromTo'],
                passed: { from: '2020.11', to: '2022.03' },
              })}
            </td>
            <td>
              <a href="https://www.salamantex.com">Salamantex GmbH</a>, 3441 Pixendorf
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {translate({
                translations,
                path: ['fromTo'],
                passed: { from: '2020.10', to: '2020.10' },
              })}
            </td>
            <td>
              <a href="https://www.ims.co.at/">IMS Nanofabrication GmbH</a>, 1200{' '}
              {translate({ translations, path: ['vienna'] })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {translate({
                translations,
                path: ['fromTo'],
                passed: { from: '2016.08', to: '2020.10' },
              })}
            </td>
            <td>
              <a href="https://www.adverity.com/">Adverity GmbH</a>, 1070{' '}
              {translate({ translations, path: ['vienna'] })}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold', fontFamily: 'inherit' }}>
              {translate({ translations, path: ['projects', 'project'] })}
            </td>
            <td style={{ fontWeight: 'bold' }}>
              {translate({ translations, path: ['projects', 'duration'] })}
            </td>
            <td style={{ fontWeight: 'bold' }}>
              {translate({ translations, path: ['projects', 'role'] })}
            </td>
          </tr>
          {projects.map(({ name, from, to, roles }) => (
            <tr key={name}>
              <td className={styles.noHeading}>
                {name}
                <br />
              </td>
              <td className={styles.noHeading}>
                {translate({
                  translations,
                  path: ['fromTo'],
                  passed: { from, to },
                })}
              </td>
              <td>
                {roles.map((role, index) => (
                  <React.Fragment key={role}>
                    {role}
                    {index < roles.length - 1 ? ', ' : null}
                  </React.Fragment>
                ))}
              </td>
            </tr>
          ))}
          <tr className={styles.separatorT}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className={styles.separatorB}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['education'] })}</td>
            <td>
              {translate({
                translations,
                path: ['fromTo'],
                passed: { from: '2010', to: '2015' },
              })}
            </td>
            <td>
              <a href="https://www.htlstp.ac.at/abteilungen/informatik">
                HTL St. Pölten Informatik
              </a>
            </td>
          </tr>
          <tr className={styles.separatorT}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className={styles.separatorB}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['skills', 'skills'] })}</td>
            <td colSpan={2}>{translate({ translations, path: ['skills', 'teamPlayer'] })}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>
              {translate({
                translations,
                path: ['skills', 'independentLearner'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>{translate({ translations, path: ['skills', 'dumbQuestions'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>{translate({ translations, path: ['skills', 'workEfficiency'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={2}>{translate({ translations, path: ['skills', 'latestTechnology'] })}</td>
          </tr>
          <tr className={styles.separatorT}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className={styles.separatorB}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['languages', 'languages'] })}</td>
            <td>{translate({ translations, path: ['languages', 'german'] })}</td>
            <td>{translate({ translations, path: ['languages', 'germanSkill'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['languages', 'english'] })}</td>
            <td>{translate({ translations, path: ['languages', 'englishSkill'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Javascript (ESNext)</td>
            <td>
              {translate({
                translations,
                path: ['languages', 'javascriptSkill'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Typescript</td>
            <td>
              {translate({
                translations,
                path: ['languages', 'typescriptSkill'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Rust</td>
            <td>{translate({ translations, path: ['languages', 'rustSkill'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Groovy</td>
            <td>{translate({ translations, path: ['languages', 'groovySkill'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Java</td>
            <td>{translate({ translations, path: ['languages', 'javaSkill'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>C</td>
            <td>{translate({ translations, path: ['languages', 'cSkill'] })}</td>
          </tr>
          <tr className={styles.separatorT}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className={styles.separatorB}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              {translate({
                translations,
                path: ['technologies', 'technologies'],
              })}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className={styles.noHeading}>Javascript</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://reactjs.org/">React</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'reactInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://redux.js.org/">Redux</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'reduxInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://storybook.js.org/">Storybook</a>
            </td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'storybookInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://jestjs.io/">Jest</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'jestInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://www.highcharts.com/">Highcharts</a>
            </td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'highchartsInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://flow.org/">Flow</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'flowInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://momentjs.com/">Moment</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'momentInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://lodash.com/">Lodash</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'lodashInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://fela.js.org/">Fela</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'felaInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://www.cypress.io/">Cypress</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'cypressInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Web components</td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'webComponentsInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://github.com/axios/axios">Axios</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'axiosInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>HTML5 Canvas</td>
            <td>{translate({ translations, path: ['technologies', 'canvasInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://nodejs.org/en/">Node.js</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'nodeInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://eslint.org/">Eslint</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'eslintInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://prettier.io/">Prettier</a>
            </td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'prettierInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td className={styles.noHeading}>PostgreSQL</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className={styles.noHeading}>SVG</td>
            <td>&nbsp;</td>
            <td>scalable vector graphics</td>
          </tr>
          <tr>
            <td className={styles.noHeading}>Rust</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://github.com/Rust-SDL2/rust-sdl2">SDL2</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'sdl2Info'] })}</td>
          </tr>
          <tr>
            <td className={styles.noHeading}>Linux</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://ubuntu.com/download/desktop">Ubuntu</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'ubuntuInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://www.archlinux.org/">Archlinux</a>
            </td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'archlinuxInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://git-scm.com/">git</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'gitInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://kde.org/plasma-desktop">KDE Plasma</a>
            </td>
            <td>
              {translate({
                translations,
                path: ['technologies', 'kdePlasmaInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://dwm.suckless.org/">dwm</a>
            </td>
            <td>{translate({ translations, path: ['technologies', 'dwmInfo'] })}</td>
          </tr>
          <tr className={styles.separatorT}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className={styles.separatorB}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{translate({ translations, path: ['hobbies', 'hobbies'] })}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['hobbies', 'programming'] })}</td>
            <td>
              <a href="https://github.com/danielbartsch">github.com/danielbartsch</a>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <a href="https://github.com/danielbartsch/art">
                {translate({ translations, path: ['hobbies', 'vectorGraphics'] })}
              </a>
            </td>
            <td>
              {translate({
                translations,
                path: ['hobbies', 'vectorGraphicsInfo'],
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['hobbies', '3DArt'] })}</td>
            <td>
              {translate({
                translations,
                path: ['hobbies', '3DArtInfo'],
                passed: <a href="https://www.blender.org/">Blender</a>,
              })}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['hobbies', 'sports'] })}</td>
            <td>{translate({ translations, path: ['hobbies', 'sportsInfo'] })}</td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['hobbies', 'reading'] })}</td>
            <td>
              <a href="https://en.wikipedia.org/wiki/The_Expanse_(novel_series)">
                {translate({ translations, path: ['hobbies', 'scienceFiction'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/The_Hitchhiker%27s_Guide_to_the_Galaxy">
                {translate({ translations, path: ['hobbies', 'comedy'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/Animal_Farm">
                {translate({ translations, path: ['hobbies', 'satires'] })}
              </a>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>{translate({ translations, path: ['hobbies', 'gaming'] })}</td>
            <td>
              <a href="https://en.wikipedia.org/wiki/Minecraft">
                {translate({ translations, path: ['hobbies', 'sandbox'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/Minecraft">
                {translate({ translations, path: ['hobbies', 'creativity'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/Europa_Universalis_IV">
                {translate({ translations, path: ['hobbies', 'history'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/Subnautica">
                {translate({ translations, path: ['hobbies', 'fantasy'] })}
              </a>
              ,{' '}
              <a href="http://survivethedistance.com/">
                {translate({ translations, path: ['hobbies', 'racing'] })}
              </a>
              ,{' '}
              <a href="https://en.wikipedia.org/wiki/Soulslike">
                {translate({ translations, path: ['hobbies', 'patience0'] })}
              </a>
              &nbsp;
              <a href="https://en.wikipedia.org/wiki/Noita_(video_game)">
                {translate({ translations, path: ['hobbies', 'patience1'] })}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Page>
)

type Project = {
  name: string
  from: string
  to: string
  roles: Array<string>
}
const projects: Array<Project> = [
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'javascriptToTypescript'],
    }),
    from: '2021.02',
    to: '2022.03',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'projectManager'],
      }),
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'openApi'],
    }),
    from: '2021.01',
    to: '2021.02',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'flowToTypescript'],
    }),
    from: '2020.04',
    to: '2020.07',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'timeline'],
    }),
    from: '2020.03',
    to: '2020.04',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'projectManager'],
      }),
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'themingExtension'],
    }),
    from: '2019.12',
    to: '2020.03',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'projectManager'],
      }),
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'jsonSchema'],
    }),
    from: '2018.10',
    to: '2018.11',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'theming'],
    }),
    from: '2017.09',
    to: '2017.10',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'dataVisualization'],
    }),
    from: '2017.03',
    to: '2020.10',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'dataExploration'],
    }),
    from: '2017.01',
    to: '2017.03',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
  {
    name: translate({
      translations,
      path: ['projects', 'projects', 'angularToReact'],
    }),
    from: '2016.11',
    to: '2017.01',
    roles: [
      translate({
        translations,
        path: ['projects', 'roles', 'softwareDeveloper'],
      }),
    ],
  },
]
