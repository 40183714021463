export const en = {
  owner: 'Owner',
  name: 'Daniel Bartsch',
  country: 'Austria',
  zip: 'ZIP',
  zipValue: '3451',
  address: 'Address',
  addressValue: 'Wiesengasse 9',
}

type Translation = typeof en

export const de: Translation = {
  owner: 'Inhaber',
  name: 'Daniel Bartsch',
  country: 'Österreich',
  zip: 'PLZ',
  zipValue: '3451',
  address: 'Adresse',
  addressValue: 'Wiesengasse 9',
}
