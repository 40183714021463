const magnitudes = ['Byte', 'KiB', 'MiB', 'GiB', 'TiB']
const MAGNITUDE = 1024

export const toReadableFileSize = (size: number): string => {
  const { compactSize, magnitudeIndex } = magnitudes.reduce(
    (accumulator: { compactSize: number; magnitudeIndex: number }) =>
      accumulator.compactSize <= MAGNITUDE / 2
        ? accumulator
        : {
            compactSize: accumulator.compactSize / MAGNITUDE,
            magnitudeIndex: accumulator.magnitudeIndex + 1,
          },
    { compactSize: size, magnitudeIndex: 0 },
  )

  return Math.round(compactSize * 100) / 100 + ' ' + magnitudes[magnitudeIndex]
}
