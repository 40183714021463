import React from 'react'

export const en = {
  ipAddress: (reference: React.ReactNode) => (
    <>
      Your IP Address will be saved if you create a Vote prompt at {reference},
      or if you vote on a prompt. The purpose of this is to present to you what
      you have voted for, and to show you which prompts you have created.
    </>
  ),
  ipAddressNoOtherPurpose:
    'Your IP Address will not be used for any other purpose.',
}

type Translation = typeof en

export const de: Translation = {
  ipAddress: (reference: React.ReactNode) => (
    <>
      Deine IP-Adresse wird gespeichert, wenn du eine Frage in {reference}{' '}
      stellst, oder wenn du eine Antwort wählst. Der Zweck der Speicherung
      besteht darin, dir anzuzeigen, was du gewählt hast, und dir zu zeigen,
      welche Fragen du gestellt hast.
    </>
  ),
  ipAddressNoOtherPurpose:
    'Deine IP-Adresse wird zu keinem anderen Zweck benutzt.',
}
