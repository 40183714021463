import * as React from 'react'
import { BaseIcon } from './'

export const Circle = (props: React.ComponentProps<typeof BaseIcon>) => (
  <BaseIcon {...props}>
    <circle
      fill="none"
      stroke="currentcolor"
      strokeWidth="2"
      r="5"
      cx="6"
      cy="6"
    />
  </BaseIcon>
)
