import * as React from 'react'
import { NavigationButton } from '../../elementals/NavigationButton'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import * as appTranslations from '../../../translations'
import { Page } from '../../elementals/Page'

export const DataProtection = () => (
  <Page>
    <p>
      {translate({
        translations,
        path: ['ipAddress'],
        passed: (
          <NavigationButton path="/votePrompts" style={{ display: 'inline-flex' }}>
            {translate({
              translations: appTranslations,
              path: ['votePrompts'],
            })}
          </NavigationButton>
        ),
      })}
    </p>
    <p>
      {translate({
        translations,
        path: ['ipAddressNoOtherPurpose'],
      })}
    </p>
  </Page>
)
