import * as React from 'react'
import { useUniqueId } from './id'
import { Spacer } from '../Spacer'
import { Text } from '../Text'
import { Circle } from '../Icons/Circle'
import { CircleDot } from '../Icons/CircleDot'
import { Square } from '../Icons/Square'
import { SquareRectangle } from '../Icons/SquareRectangle'

type Props = React.ComponentProps<'input'> & {
  children: React.ReactNode
  icon?: React.ReactNode | null
}

const Chooser = ({
  children,
  id,
  style,
  className,
  icon,
  title,
  ...props
}: Props) => {
  const labelId = useUniqueId() + (id ?? '')
  return (
    <>
      <input id={labelId} {...props} />
      <Text
        tag="label"
        htmlFor={labelId}
        style={style}
        className={className}
        title={title}
      >
        <Spacer space={8}>
          {icon}
          <div style={{ flex: 1 }}>{children}</div>
        </Spacer>
      </Text>
    </>
  )
}

export const Radio = ({
  checked,
  icon = checked ? <CircleDot /> : <Circle />,
  ...props
}: Props) => <Chooser type="radio" icon={icon} checked={checked} {...props} />

export const Checkbox = ({
  checked,
  icon = checked ? <SquareRectangle /> : <Square />,
  ...props
}: Props) => (
  <Chooser type="checkbox" icon={icon} checked={checked} {...props} />
)
