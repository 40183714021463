import * as React from 'react'

export const en = {
  prompt404: {
    sorry: 'Sorry!',
    notFound: 'Prompt not found!',
  },
  prompt: {
    private: 'private',
    yours: 'yours',
    deleteConfirm: 'You are about to delete your prompt.',
    promptedOn: (when: React.ReactNode) => <>opened {when}</>,
    answerVotes: (votes: number) =>
      `${votes === 0 ? 'no' : votes} ${votes === 1 ? 'vote' : 'votes'}`,
    invalidationFuture: (when: string) => <>invalidates {when}</>,
    invalidationPast: (when: string) => <>invalidated {when}</>,
  },
  createPrompt: {
    promptPlaceholder: 'Prompt your question here',
    answerPlaceholder: (number: number) => 'Answer ' + number,
    invalidation: 'Closed at',
    public: 'Public',
    publicInfo: 'Only visible with a direct link',
  },
}

type Translation = typeof en

export const de: Translation = {
  prompt404: {
    sorry: 'Sorry!',
    notFound: 'Frage nicht auffindbar',
  },
  prompt: {
    private: 'privat',
    yours: 'deins',
    deleteConfirm: 'Möchtest du deine Frage wirklich löschen?',
    promptedOn: (when: React.ReactNode) => <>{when} gestellt</>,
    answerVotes: (votes: number) =>
      `${votes === 0 ? 'keine' : votes} ${votes === 1 ? 'Stimme' : 'Stimmen'}`,
    invalidationFuture: (when: string) => <>läuft {when} aus</>,
    invalidationPast: (when: string) => <>{when} ausgelaufen</>,
  },
  createPrompt: {
    promptPlaceholder: 'Stell eine Frage',
    answerPlaceholder: (number: number) => 'Antwort ' + number,
    invalidation: 'Läuft aus',
    public: 'Öffentlich',
    publicInfo: 'Nur mit Direktlink sichtbar',
  },
}
