import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { ContentBox } from '../../elementals/ContentBox'
import { Button } from '../../elementals/Button'
import { PageAnchor } from '../../elementals/PageAnchor'
import { SpaceInvaders } from './SpaceInvaders'
import { RoyalUr } from './RoyalUr'
import { Overcooked } from './Overcooked'
import { Hexagon } from './Hexagon'
import { isProduction } from '../../../utils/dev'
import { Page } from '../../elementals/Page'

export const Games = () => {
  const selectedAnchorName = (useHistory().location.hash ?? '').replace('#', '')

  const [showSpaceInvaders, setShowSpaceInvaders] = React.useState(false)
  const [showRoyalUr, setShowRoyalUr] = React.useState(false)
  const [showOvercooked, setShowOvercooked] = React.useState(false)
  return (
    <Page>
      <details open={selectedAnchorName === 'spaceInvaders'}>
        <summary>
          <h1>
            <PageAnchor name="spaceInvaders">Space Invaders</PageAnchor>
          </h1>
        </summary>
        <p>The original Space Invaders is an arcade game from 1978. It was quite popular.</p>
        <p>
          Below you can checkout my own interpretation on this classic. Done in about 2 hours
          programming time.
        </p>
        <div style={{ display: 'flex' }}>
          <ContentBox title="Controls">
            <table cellSpacing="0">
              <tbody>
                <tr>
                  <td>Left/Right</td>
                  <td>-</td>
                  <td>Move</td>
                </tr>
                <tr>
                  <td>Up</td>
                  <td>-</td>
                  <td>Shoot</td>
                </tr>
              </tbody>
            </table>
          </ContentBox>
        </div>
        <br />
        {!showSpaceInvaders && (
          <Button data-type="primary" onClick={() => setShowSpaceInvaders(true)}>
            Start Game
          </Button>
        )}
        {showSpaceInvaders && <SpaceInvaders />}
        <br />
      </details>
      <details open={selectedAnchorName === 'ur'}>
        <summary>
          <h1>
            <PageAnchor name="ur">Royal Game of Ur</PageAnchor>
          </h1>
        </summary>
        <p>
          The Royal Game of Ur is a 2-player board game that's thousands of years old. It's the
          ancestor of the common game 'Ludo' (or 'Mensch ärgere dich nicht' in the German-speaking
          world).
        </p>
        <p>
          Below you can checkout my own interpretation on this board game. This was done in about 4
          hours programming time.
        </p>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '1em' }}>
            <ContentBox title="Rules">
              <p>
                The Royal Game of Ur is a turn based race game. There are two players, each of which
                have to put their pawns to the finish line as fast as possible.
              </p>
              <p>
                But watch out! In the middle column of the game, pawns can kick each other out, if
                an enemy pawn lands exactly at another pawn. The fields 1 to 4, 13 and 14, as well
                as the * field are safe spaces.
              </p>
              <p>
                Each round, the player can decide whether to roll the dice, or to add a new pawn to
                the beginning of the board (this of course isn't possible, if another pawn is
                blocking the starting line).
              </p>
              <p>
                The finish line has to be hit exactly. A pawn will not be finished, if the player
                rolls the dice with a higher number than needed.
              </p>
            </ContentBox>
          </div>
          <ContentBox title="Controls">
            <p>Beginning of your turn</p>
            <ul>
              <li>You can choose whether to roll the dice,</li>
              <li>
                or to add another pawn to the board. Just click with your mouse on the 'roll', or
                the 'new pawn' text.
              </li>
            </ul>
            Ending of your turn
            <ul>
              <li>If you added a new pawn to the board, your turn will be over immediately.</li>
              <li>
                If you rolled the dice, and the result was 0, your turn will be over immediately.
              </li>
              <li>
                If you rolled the dice with more than 0, you'll see the number where there was
                'roll' previously. You can then just click on one of your pawns that you want the
                advancement to apply to.
              </li>
            </ul>
          </ContentBox>
        </div>
        <br />
        {!showRoyalUr && (
          <Button data-type="primary" onClick={() => setShowRoyalUr(true)}>
            Start Game
          </Button>
        )}
        {showRoyalUr && <RoyalUr />}
      </details>
      <details open={selectedAnchorName === 'overcooked'}>
        <summary>
          <h1>
            <PageAnchor name="overcooked">Overcooked</PageAnchor>
          </h1>
        </summary>
        <p>
          The original 2016 game of the same name is a multiplayer game focused on teamwork. The
          goal is to cook under time pressure with the need of good team work.
        </p>
        <p>
          Below you can check out my showcase of the basic gameplay principles. It's not meant to be
          a game. It just shows the basic concepts of moving food around, and processing it.
        </p>
        <p>It was programmed in about 2 hours.</p>
        <div style={{ display: 'flex' }}>
          <ContentBox title="Controls">
            <table cellSpacing="0">
              <tbody>
                <tr>
                  <td>Arrow keys</td>
                  <td>-</td>
                  <td>Move</td>
                </tr>
                <tr>
                  <td>Z</td>
                  <td>-</td>
                  <td>Take/Put items</td>
                </tr>
              </tbody>
            </table>
          </ContentBox>
        </div>
        <br />
        {!showOvercooked && (
          <Button data-type="primary" onClick={() => setShowOvercooked(true)}>
            Start Game
          </Button>
        )}
        {showOvercooked && <Overcooked />}
      </details>
      {!isProduction && (
        <details open={selectedAnchorName === 'hexagon'}>
          <summary>
            <h1>
              <PageAnchor name="hexagon">Hexagon</PageAnchor>
            </h1>
          </summary>
          <Hexagon />
        </details>
      )}
    </Page>
  )
}
