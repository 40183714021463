export const en = {
  cheer: 'Cheer',
  hasCheered: (cheers: number) => 'cheered ' + cheers + 'x',
}

type Translation = typeof en

export const de: Translation = {
  cheer: 'Jubeln',
  hasCheered: (cheers: number) => cheers + 'x gejubelt',
}
