import * as React from 'react'
import { BaseIcon } from './'

export const Check = (props: React.ComponentProps<typeof BaseIcon>) => (
  <BaseIcon {...props}>
    <polyline
      points="11,2 4,9 1,6"
      fill="none"
      strokeWidth="2"
      stroke="currentcolor"
    />
  </BaseIcon>
)
