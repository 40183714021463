import * as React from 'react'

const sizes = {
  normal: '1em',
  big: '1.25em',
} as const

type Size = keyof typeof sizes

type Props = React.ComponentProps<'svg'> & { size?: Size }

export const BaseIcon = ({ children, size = 'normal', ...props }: Props) => (
  <svg viewBox="0 0 12 12" width={sizes[size]} height={sizes[size]} {...props}>
    {children}
  </svg>
)
