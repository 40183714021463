import * as React from 'react'
import * as translations from './translations'
import { translate } from '../../../utils/translations'
import { Page } from '../../elementals/Page'

export const Imprint = () => (
  <Page>
    <table>
      <tbody>
        <tr>
          <td style={{ width: 100 }}>{translate({ translations, path: ['owner'] })}</td>
          <td>{translate({ translations, path: ['name'] })}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>{translate({ translations, path: ['country'] })}</td>
        </tr>
        <tr>
          <td>{translate({ translations, path: ['zip'] })}</td>
          <td>{translate({ translations, path: ['zipValue'] })}</td>
        </tr>
        <tr>
          <td>{translate({ translations, path: ['address'] })}</td>
          <td>{translate({ translations, path: ['addressValue'] })}</td>
        </tr>
      </tbody>
    </table>
  </Page>
)
