import * as React from 'react'
import { Link } from 'react-router-dom'

type Props<Tag extends 'span' | 'label'> = React.ComponentProps<Tag> & {
  tag?: Tag
}

export const Text = <Tag extends 'span' | 'label'>({
  tag,
  title,
  style,
  ...props
}: Props<Tag>) => {
  const Component = (tag ?? 'span') as any
  return (
    <Component
      {...props}
      title={title}
      style={{
        ...(title
          ? { textDecoration: 'underline dotted', cursor: 'help' }
          : undefined),
        ...style,
      }}
    />
  )
}
