import * as React from 'react'
import styles from './styles.module.css'

type Props = React.ComponentProps<'a'> & {
  name: string
}

export const PageAnchor = ({ name, children, ...props }: Props) => {
  return (
    <span className={styles.container}>
      {children}
      <a id={name} href={`#${name}`} className={styles.pageAnchor} {...props}>
        #
      </a>
    </span>
  )
}
