import React from 'react'

export const en = {
  contactInformation: 'Contact information',
  birthDate: 'Birth date',
  citizenship: 'Citizenship',
  maritalStatus: 'Marital status',
  maritalStatusValue: 'married',
  education: 'Education',
  jobs: 'Jobs',
  since: (when: string) => 'since ' + when,
  fromTo: ({ from, to }: { from: string; to: string }) => from + ' – ' + to,
  vienna: 'Vienna',
  projects: {
    project: 'Project',
    duration: 'Duration',
    role: 'Role',
    roles: {
      softwareDeveloper: 'Software developer',
      projectManager: 'Project manager',
    },
    projects: {
      javascriptToTypescript:
        'Gradual introduction of TypeScript in a pure typeless JavaScript codebase',
      openApi: 'OpenAPI (Swagger) generation + integration into existing codebase',
      flowToTypescript: 'Migration from ES6+Flow to Typescript',
      timeline: 'Audit log / timeline',
      themingExtension: 'Theming extension',
      jsonSchema: 'JSON-Schema form generator',
      theming: 'Theming',
      dataVisualization: 'Data visualizations + customization',
      dataExploration: 'Data exploration tool (Redux, UI, Data aggregation logic)',
      angularToReact: 'Rewrite of legacy Angular parts of the Application to React',
    },
  },
  skills: {
    skills: 'Skills',
    teamPlayer: 'team player',
    independentLearner: 'independent learner',
    dumbQuestions: 'always asking the "dumb" questions',
    workEfficiency: 'taking special care for work efficiency',
    latestTechnology: 'always up for the latest technology',
  },
  languages: {
    languages: 'Languages',
    german: 'German',
    germanSkill: 'native (excellent)',
    english: 'English',
    englishSkill: 'excellent',
    javascriptSkill: 'excellent',
    typescriptSkill: 'excellent',
    rustSkill: 'basic',
    groovySkill: 'basic',
    javaSkill: 'basic',
    cSkill: 'basic',
  },
  technologies: {
    technologies: 'Technologies',
    reactInfo: 'very popular web rendering framework',
    reduxInfo: 'popular state manager for web apps',
    storybookInfo: 'popular technology to enable component exploration and visual testing',
    jestInfo: 'popular javascript/typescript testing framework',
    highchartsInfo: 'data visualization engine',
    flowInfo: 'separate javascript type system',
    momentInfo: 'Time and date library',
    lodashInfo: 'General purpose utility library',
    felaInfo: 'Dynamic theming framework',
    cypressInfo: 'Integration testing framework',
    webComponentsInfo: 'enables custom html tags with custom behaviour and visualization',
    axiosInfo: 'popular http client',
    canvasInfo: 'Browser built-in rasterized rendering',
    nodeInfo: 'C++ runtime for javascript',
    eslintInfo: 'making code style enforcable and predictable',
    prettierInfo: 'opinionated code formatter',
    sdl2Info: 'bare bones 2D rendering and game engine',
    ubuntuInfo: 'popular distribution',
    archlinuxInfo: 'popular minimalist distribution',
    gitInfo: 'popular version control system',
    kdePlasmaInfo: 'popular desktop environment',
    dwmInfo: '"compile your own" tiling window manager',
  },
  hobbies: {
    hobbies: 'Hobbies',
    programming: 'Programming',
    vectorGraphics: 'SVG creation',
    vectorGraphicsInfo: 'exclusively manually, without visual editors',
    '3DArt': '3D Art',
    '3DArtInfo': (what: React.ReactNode) => <>using {what}</>,
    sports: 'sports',
    sportsInfo: 'running, cycling, walking',
    reading: 'reading',
    scienceFiction: 'science fiction',
    comedy: 'comedy',
    satires: 'satires',
    gaming: 'gaming',
    sandbox: 'sandbox',
    creativity: 'creativity',
    history: 'history',
    fantasy: 'fantasy',
    racing: 'racing',
    patience0: 'practice of',
    patience1: 'patience',
  },
}

type Translation = typeof en

export const de: Translation = {
  contactInformation: 'Kontaktinformationen',
  birthDate: 'Geburtstag',
  citizenship: 'Staatsbürgerschaft',
  maritalStatus: 'Personenstand',
  maritalStatusValue: 'verheiratet',
  education: 'Bildung',
  jobs: 'Jobs',
  since: (when: string) => 'seit ' + when,
  fromTo: ({ from, to }: { from: string; to: string }) => from + ' – ' + to,
  vienna: 'Wien',
  projects: {
    project: 'Projekt',
    duration: 'Dauer',
    role: 'Rolle',
    roles: {
      softwareDeveloper: 'Softwareentwickler',
      projectManager: 'Projektmanager',
    },
    projects: {
      javascriptToTypescript:
        'Graduelle Einführung von TypeScript in einer komplett typenlosen JavaScript Codebasis',
      openApi: 'OpenAPI (Swagger) Generierung + Integration in bestehendes System',
      flowToTypescript: 'Migrierung von ES6+Flow zu Typescript',
      timeline: 'Darstellung von Timelines',
      themingExtension: 'Theming Erweiterung',
      jsonSchema: 'JSON-Schema Formulargenerator',
      theming: 'Theming',
      dataVisualization: 'Datenvisualisierung + Anpassung',
      dataExploration: 'Datenexplorationstool (Redux, UI, Aggregierungslogik)',
      angularToReact: 'Neuschreibung von Teilen des Systems von Angular auf React',
    },
  },
  skills: {
    skills: 'Fähigkeiten',
    teamPlayer: 'team-player',
    independentLearner: 'eigenständiges Lernen',
    dumbQuestions: 'stelle immer "dumme" Fragen',
    workEfficiency: 'besonderes Augenmerk auf Arbeitseffizienz',
    latestTechnology: 'stets up-to-date mit relevanter Technologie',
  },
  languages: {
    languages: 'Sprachen',
    german: 'Deutsch',
    germanSkill: 'Muttersprache (ausgezeichnet)',
    english: 'Englisch',
    englishSkill: 'ausgezeichnet',
    javascriptSkill: 'ausgezeichnet',
    typescriptSkill: 'ausgezeichnet',
    rustSkill: 'grundlegend',
    groovySkill: 'grundlegend',
    javaSkill: 'grundlegend',
    cSkill: 'grundlegend',
  },
  technologies: {
    technologies: 'Technologien',
    reactInfo: 'sehr beliebtes Web-Rendering-Framework',
    reduxInfo: 'beliebter State-Manager für Web-Apps',
    storybookInfo: 'beliebte Lösung für Komponentenorganisation und visuelles Testen',
    jestInfo: 'beliebtes Javascript/Typescript Testframework',
    highchartsInfo: 'Datenvisualisierungs-Engine',
    flowInfo: 'separates Javascript Type System',
    momentInfo: 'Date-Time Bibliothek',
    lodashInfo: 'Javascript-Allzweckbibliothek',
    felaInfo: 'Dynamisches-Theming-Framework',
    cypressInfo: 'Integrations-Test-Framework',
    webComponentsInfo: 'ermöglicht eigene HTML Tags mit eigens definiertem Verhalten und Aussehen',
    axiosInfo: 'beliebter HTTP Client',
    canvasInfo: 'Browser-eigenes rasterisiertes Rendering',
    nodeInfo: 'C++ runtime für Javascript',
    eslintInfo: 'Codestyle-, und -Korrektheits-checker',
    prettierInfo: 'Codeformattierer',
    sdl2Info: 'minimale 2D Rendering- und Spiele-Engine',
    ubuntuInfo: 'beliebte Distribution',
    archlinuxInfo: 'beliebte Minimaldistribution',
    gitInfo: 'beliebtes Versionierungssystem',
    kdePlasmaInfo: 'beliebte Desktopumgebung',
    dwmInfo: '"compile your own" tiling Fenstermanager',
  },
  hobbies: {
    hobbies: 'Hobbies',
    programming: 'Programmieren',
    vectorGraphics: 'Vectorgrafiken erstellen',
    vectorGraphicsInfo: 'nur manuell, ohne visuelle Editoren',
    '3DArt': '3D Kunst',
    '3DArtInfo': (what: React.ReactNode) => <>mittels {what}</>,
    sports: 'Sport',
    sportsInfo: 'laufen, radfahren, spazieren',
    reading: 'Lesen',
    scienceFiction: 'Sci-fi',
    comedy: 'Komödien',
    satires: 'Satiren',
    gaming: 'Computerspielen',
    sandbox: 'Sandbox',
    creativity: 'Kreativität',
    history: 'Geschichte',
    fantasy: 'Fantasie',
    racing: 'Rennspiele',
    patience0: 'Gedulds-',
    patience1: 'Übungen',
  },
}
