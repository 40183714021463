export const en = {
  home: 'Me',
  curriculumVitae: 'Curriculum Vitae',
  votePrompts: 'Vote prompts',
  games: 'Games',
  populationDensityExperiment: 'Population Density Experiment',
  dataProtection: 'Data protection',
  imprint: 'Imprint',
  radweg: 'Bicycle path',
  torii: 'Torii',
}

type Translation = typeof en

export const de: Translation = {
  home: 'Ich',
  curriculumVitae: 'Lebenslauf',
  votePrompts: 'Fragen',
  games: 'Spiele',
  populationDensityExperiment: 'Bevölkerungsdichtenexperiment',
  dataProtection: 'Datenschutz',
  imprint: 'Impressum',
  radweg: 'Radweg',
  torii: 'Torii',
}
