import { Nullable } from '../types'
import { get } from 'lodash'

const getLanguage = <
  Translation,
  T extends { de: Translation; en: Translation }
>(
  translations: T,
): Nullable<Translation> => {
  const language = navigator.language.split('-')[0]

  if (language === 'de' || language === 'en') {
    return translations[language]
  }
  return translations.en
}

type Props<Translation extends object, TranslationParameter> = {
  translations: { en: Translation; de: Translation }
  path: Array<string>
  passed?: TranslationParameter
}

export const translate = <Translation extends object, TranslationParameter>({
  translations,
  path,
  passed,
}: Props<Translation, TranslationParameter>) => {
  const translation = get(getLanguage(translations), path)
  if (typeof translation === 'function') {
    return translation(passed)
  }
  return translation
}
