import * as React from 'react'
import { Spacer } from '../../elementals/Spacer'
import RadwegSperreMichelhausen from './RadwegSperreMichelhausen.webp'
import RadwegSperreLangenrohr from './RadwegSperreLangenrohr.webp'
import './styles.module.css'
import { CheerButton } from '../../features/CheerButton'
import { Page } from '../../elementals/Page'

const Header = ({ date, children }: { date?: React.ReactNode; children: React.ReactNode }) => (
  <>
    <h1 style={{ marginBlockEnd: 0, wordBreak: 'break-word' }}>{children}</h1>
    <span style={{ opacity: 0.7, marginBlockEnd: '2em', fontSize: '0.95em' }}>{date}</span>
  </>
)

export const Radweg = () => (
  <Page>
    <Spacer alignment="start" orientation="vertical" space={16}>
      <div>
        <Header>Radwege&shy;verbesserung</Header>
        <p>Servus!</p>
        <p>
          In den folgenden Kapiteln erfährst du, wie ich mich damit herumschlage, den Radweg in der
          Gemeinde Michelhausen etwas zu verbessern. Das geht leider nur durch nerven von
          Politikern.
        </p>
        <p>
          Die Beiträge sind umgekehrt chronologisch sortiert. Das heißt ganz unten findest du den
          Ersten, ganz oben den neuesten Beitrag.
        </p>
      </div>
      <div>
        <Header date="12. Mai 2022">Kommunikations&shy;protokoll</Header>
        <p>
          Am 22. März 2022 fand eine Gemeinderatssitzung im Amtshaus von Michelhausen statt. Diese
          Gelegenheit habe ich genutzt, um Herrn Sanda über die Radwegesperren auszufragen.
        </p>
        <p>
          Leider konnte ich aus dem Gespräch nur heraushören, dass noch nichts von seiner Seite
          unternommen wurde. Außerdem wurde als Ausrede "Sicherheitsbedenken" geäußert. Das sind
          natürlich valide Bedenken, allerdings handelt es sich hier um ein Problem, das in anderen
          Teilen der Welt bereits gelöst wurde.
        </p>
        <p>
          In den nächsten Tagen danach habe ich Herrn Sanda eine E-Mail mit Lösungen für genau jene
          Situationen zukommen lassen, die beim Radweg vorherrschen: ein alleiniger Radweg mit
          sporadischen Kreuzungen von Straßen.
        </p>
        <p>
          Im Fahrradland Nummer 1, den Niederlanden, ist der Sicherheitsaspekt (alle
          Verkehrsteilnehmer: Auto als auch Fahrrad zischen nur so über die Kreuzung) folgendermaßen
          gelöst:
          <ol>
            <li>
              der Radweg ist leicht erhöht, und dient dem kreuzenden Verkehr als Bodenwelle (
              <a href="https://www.google.at/maps/@52.0222384,5.1925922,3a,75y,128.65h,63.01t/data=!3m6!1e1!3m4!1sF8iR0x3bHklnD1lJLDS9tA!2e0!7i13312!8i6656">
                Beispiel
              </a>
              )
            </li>
          </ol>
        </p>
        <p>
          Nach Erhalt der E-Mail, und einer Erinnerung meinerseits einen Monat später, hat mir Herr
          Sanda wiedereinmal versichert, dass sich Zitat <q>in den nächsten Tagen (Wochen)</q> was
          tun werde, und zwar ein "Pilotversuch" bei{' '}
          <a href="https://www.openstreetmap.org/#map=19/48.29072/15.97815&layers=N">einer</a> der
          sechs Kreuzungen des Radweges.
        </p>
        <p>Stand 26. April 2022</p>
      </div>
      <div>
        <Header date="3. November 2021">Danke</Header>
        <p>Danke für Eure Unterstützung!</p>
        <p>
          Meine kleine Kampagne hat offenbar gewirkt, denn Eduard Sanda hat mir versichert, dass die
          ÖBB einem Umbau der Radwegsperren zugestimmt hat. Sie sollen in etwa so aussehen, wie in
          Langenrohr (Bild unten).
        </p>
        <p>Laut Herrn Sanda soll die Umsetzung von der Gemeinde "zeitnah" erfolgen.</p>
      </div>
      <Spacer
        orientation="vertical"
        alignment="center"
        style={{ width: '100%', alignItems: 'center' }}
      >
        <CheerButton topic="radweg" />
      </Spacer>
      <hr />
      <hr />
      <span style={{ textAlign: 'center', width: '100%' }}>Hier folgt der Originalartikel</span>
      <hr />
      <hr />
      <div>
        <Header date="7. Oktober 2021">Angenehmere Radwege</Header>
        <p>
          <a href="https://www.michelhausen.gv.at/Sanda_Eduard">Eduard Sanda</a>, als
          Mobilitätsbeauftragter der Marktgemeinde Michelhausen, ist dafür verantwortlich, die
          Mobilität der Gemeindebürger zu gewährleisten.
        </p>
        <p>
          Der Radweg von Michelhausen bis fast Judenau ist ein sehr guter Anreiz. Leider ist er
          nicht sehr angenehm zu bewältigen, da man ständig dazu gezwungen ist, zum Stillstand zu
          kommen. Es geht hierbei um, nennen wir sie mal Radwegsperren:
        </p>
        <img src={RadwegSperreMichelhausen} style={{ maxWidth: '100%' }} />
        <p>
          Wie deutlich zu erkennen ist, fahren die meisten Radfahrenden seitlich vorbei. Also selbst
          wenn die Radwegsperren sinnvoll wären, würden sie nicht richtig funktionieren.
        </p>
        <p>
          Laut Herrn Sanda sind die Radwegsperren dazu da, die Einfahrt von Autos oder Motorrädern
          zu verhindern. Autos sind hier sehr gut verhindert, Motorräder können hingegen genau wie
          Radfahrende seitlich vorbei fahren.
        </p>
        <p>
          Speziell dafür zu sorgen, dass keine Autos den Radweg benutzen, geht mit weitaus weniger
          eingreifenden Methoden, wie das Beispiel aus der Nachbargemeinde Langenrohr zeigt:
        </p>
        <img src={RadwegSperreLangenrohr} style={{ maxWidth: '100%' }} />
        <p>
          Im Gegensatz zu Langenrohr ist der Radweg in Michelhausen laut Herrn Sanda im Besitz der
          ÖBB, wodurch sich die Gemeinde aus der Verantwortung entzieht. Wenn du mithelfen willst,
          den Radweg angenehmer zu gestalten, erhöhe den Druck der Gemeinde, indem du Herrn Sanda,{' '}
          <a href="https://www.michelhausen.gv.at/Politik/Gemeinderat">
            Frau Figl (Umweltgemeinderätin)
          </a>{' '}
          und/oder der ÖBB eine Email schreibst.
        </p>
        <p>
          Keine Angst, ich habe eine E-Mail für dich vorbereitet, klick nur unten, und dein
          E-Mail-Programm wird sich mit der vordefinierten E-Mail öffnen:
        </p>
        <p style={{ fontStyle: 'italic', opacity: 0.6 }}>// entfernt, da das Ziel erreicht wurde</p>
      </div>
    </Spacer>
  </Page>
)
