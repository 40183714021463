import * as React from 'react'
import { BaseIcon } from './'

export const Left = (props: React.ComponentProps<typeof BaseIcon>) => (
  <BaseIcon {...props}>
    <polyline
      points="8,2 4,6 8,10"
      fill="none"
      strokeWidth="2"
      stroke="currentcolor"
    />
  </BaseIcon>
)
