import { maxBy } from 'lodash'
import * as React from 'react'
import { GameState, Vector2 } from './types'
import { add, scale } from './vector'

export const useGameState = (dimensions: {
  width: number
  height: number
}): { current: GameState } =>
  React.useRef(
    addPaths(dimensions, {
      field: [
        [
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
        ],
        [
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [], pawnIndex: 0 },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [], pawnIndex: 1 },
        ],
        [
          { isHovered: false, path: [], isRendered: false },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
        ],
        [
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
        ],
        [
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
        ],
        [
          { isHovered: false, path: [], pawnIndex: 2 },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [], pawnIndex: 3 },
        ],
        [
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
          { isHovered: false, path: [] },
        ],
      ],
      mouse: { position: [-100, -100], down: false, click: undefined },
      currentTeamIndex: 1,
      currentAction: { type: 'selectingPawn' },
      pawns: [
        { isSelected: false, text: 'a', lives: 1, actionPoints: 1, teamIndex: 0 },
        { isSelected: false, text: 'b', lives: 1, actionPoints: 1, teamIndex: 0 },
        { isSelected: false, text: 'y', lives: 1, actionPoints: 1, teamIndex: 1 },
        { isSelected: false, text: 'z', lives: 1, actionPoints: 1, teamIndex: 1 },
      ],
      teams: [
        { color: '#a448', isDefeated: false },
        { color: '#4a48', isDefeated: false },
      ],
    }),
  )

const addPaths = (
  dimensions: { width: number; height: number },
  gameState: GameState,
): GameState => {
  const cellRadius = 50

  const heightReduction = 12.5
  const widthReduction = 6.5
  const cellFullHeight = cellRadius - heightReduction
  const cellFullWidth = cellRadius - widthReduction

  const hexagonPath = getHexagonPath(cellRadius / 2)

  const widestRow = maxBy(gameState.field, 'length')?.length ?? 0
  const fieldWidth = widestRow * cellFullWidth
  const fieldXCenterTranslation: Vector2 = [dimensions.width / 2 - fieldWidth / 2, 35]

  const hexagonsTranslation: Vector2 = add([-50, 10], fieldXCenterTranslation)
  gameState.field.forEach((row, rowIndex) => {
    const hexagonOffset = (cellFullWidth / 2) * rowIndex
    const yPos = rowIndex * cellFullHeight + 15
    row.forEach((cell, columnIndex) => {
      if (cell.isRendered === false) {
        cell.path = []
      } else {
        const xPos = columnIndex * cellFullWidth + hexagonOffset + 7
        cell.path = hexagonPath.map(pathVector =>
          add(hexagonsTranslation, add(pathVector, [xPos, yPos])),
        )
      }
    })
  })
  return gameState
}

const hexagonPath: Array<Vector2> = [
  [Math.cos(Math.PI / 2), Math.sin(Math.PI / 2)],
  [Math.cos((Math.PI * 5) / 6), Math.sin((Math.PI * 5) / 6)],
  [Math.cos((Math.PI * 7) / 6), Math.sin((Math.PI * 7) / 6)],
  [Math.cos((Math.PI * 3) / 2), Math.sin((Math.PI * 3) / 2)],
  [Math.cos((Math.PI * 11) / 6), Math.sin((Math.PI * 11) / 6)],
  [Math.cos(Math.PI / 6), Math.sin(Math.PI / 6)],
]
const getHexagonPath = (radius: number) => hexagonPath.map(pathVector => scale(pathVector, radius))
