import * as React from 'react'
import { NavigationButton } from '../../elementals/NavigationButton'
import { Spacer } from '../../elementals/Spacer'
import background from './background.svg'

export const NotFound = () => (
  <div
    style={{
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <img
      src={background}
      style={{
        position: 'absolute',
        objectFit: 'cover',
        height: '100%',
        width: '100%',
      }}
    />
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Spacer
        orientation="vertical"
        space={16}
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          color: '#222',
          fontSize: '2em',
        }}
      >
        {possibleMessages[Math.floor(Math.random() * possibleMessages.length)]}
        <NavigationButton path="/">↖️ Back</NavigationButton>
      </Spacer>
    </div>
  </div>
)

const possibleMessages = [
  <>
    <div>
      Woepsie Doepsie,
      <br />
      this resource can't be found.
    </div>
    <div>¯\_(ツ)_/¯</div>
  </>,
  <div>
    Oh noo!
    <br />
    the resource you requested
    <br />
    doesn't exist!
  </div>,
  <>
    <div>
      Oops,
      <br />
      The URL is probably wrong,
      <br />
      as this doesn't exist
      <br />
    </div>
    <div>¯\_(ツ)_/¯</div>
  </>,
  <>
    <div>
      The URL you entered
      <br />
      doesn't yield anything
    </div>
    <div>
      Instead, take a look at these
      <br />
      wonderful colors
    </div>
  </>,
  <div>The perfect 'Not Found' page doesn't exi..</div>,
]
