import * as React from 'react'
import { BaseIcon } from './'

export const SquareRectangle = (
  props: React.ComponentProps<typeof BaseIcon>,
) => (
  <BaseIcon {...props}>
    <rect
      fill="none"
      x="1"
      y="1"
      strokeWidth="2"
      width="10"
      height="10"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <rect
      fill="currentcolor"
      x="4"
      y="4"
      strokeWidth="2"
      width="4"
      height="4"
      stroke="currentcolor"
    />
  </BaseIcon>
)
