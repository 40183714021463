import * as React from 'react'
import { Spacer } from '../../elementals/Spacer'
import { Button } from '../../elementals/Button'
import { xhr } from '../../../xhr'
import * as translations from './translations'
import { translate } from '../../../utils/translations'

type Props = {
  topic: string
}

export const CheerButton = ({ topic }: Props) => {
  const [cheers, setCheers] = React.useState(0)
  const [canCheer, setCanCheer] = React.useState(false)

  const updateState = ({
    data: { cheers, canCheer },
  }: {
    data: { cheers: number; canCheer: boolean }
  }) => {
    setCheers(cheers)
    setCanCheer(canCheer)
  }

  React.useEffect(() => {
    xhr.get(`/api/cheer?topic=${topic}`).then(updateState)
  }, [topic])
  return (
    <Button
      data-type="primary"
      style={{ width: '13em', height: '8em' }}
      disabled={!canCheer}
      onClick={() => {
        setCheers(prev => prev + 1)
        xhr.post('/api/cheer', { cheerTopic: topic }).then(updateState)
      }}
    >
      <Spacer
        orientation="vertical"
        alignment="center"
        style={{ width: '100%' }}
      >
        {cheers
          ? translate({ translations, path: ['hasCheered'], passed: cheers })
          : translate({ translations, path: ['cheer'] })}
      </Spacer>
    </Button>
  )
}
