export type Point = [number, number]

export const michelhausen: Array<Point> = [
  [64, 27],
  [49, 15],
  [16, 34],
  [81, 16],
  [92, 16],
  [84, 36],
  [93, 38],
  [100, 42],
  [120, 23],
  [114, 13],
  [124, 6],
  [114, 4],
  [132, 20],
  [137, 28],
  [153, 35],
  [167, 37],
  [172, 20],
  [183, 38],
  [189, 42],
  [196, 26],
  [210, 33],
  [198, 51],
  [205, 56],
  [217, 62],
  [214, 26],
  [222, 26],
  [230, 32],
  [231, 40],
  [240, 35],
  [249, 35],
  [274, 33],
  [283, 37],
  [261, 35],
  [305, 43],
  [332, 56],
  [356, 64],
  [388, 75],
  [79, 48],
  [85, 49],
  [94, 52],
  [104, 54],
  [78, 59],
  [85, 59],
  [104, 64],
  [115, 61],
  [95, 80],
  [87, 83],
  [76, 84],
  [69, 99],
  [57, 103],
  [78, 101],
  [88, 100],
  [122, 84],
  [124, 54],
  [137, 55],
  [147, 58],
  [154, 61],
  [165, 56],
  [175, 58],
  [219, 91],
  [201, 97],
  [200, 104],
  [201, 114],
  [200, 126],
  [197, 138],
  [187, 147],
  [169, 151],
  [154, 151],
  [142, 153],
  [133, 156],
  [124, 157],
  [118, 158],
  [100, 160],
  [92, 162],
  [76, 164],
  [57, 166],
  [51, 169],
  [146, 143],
  [154, 137],
  [154, 130],
  [146, 131],
  [148, 120],
  [157, 115],
  [149, 110],
  [150, 104],
  [159, 101],
  [148, 95],
  [162, 82],
  [19, 193],
  [29, 195],
  [40, 185],
  [15, 202],
  [12, 211],
  [11, 231],
  [26, 233],
  [24, 250],
  [34, 235],
  [46, 239],
  [49, 223],
  [50, 207],
  [50, 199],
  [36, 197],
  [64, 180],
  [62, 192],
  [63, 201],
  [63, 209],
  [61, 225],
  [59, 238],
  [73, 243],
  [73, 232],
  [84, 242],
  [86, 226],
  [87, 214],
  [88, 201],
  [79, 178],
  [90, 175],
  [96, 175],
  [87, 188],
  [104, 173],
  [115, 172],
  [108, 187],
  [132, 168],
  [138, 167],
  [145, 166],
  [167, 163],
  [173, 171],
  [185, 162],
  [190, 157],
  [185, 181],
  [172, 185],
  [182, 193],
  [169, 193],
  [166, 220],
  [113, 245],
  [124, 245],
  [101, 245],
  [163, 245],
  [177, 249],
  [169, 233],
  [174, 226],
  [179, 214],
  [186, 205],
  [192, 194],
  [199, 175],
  [201, 162],
  [202, 188],
  [216, 171],
  [228, 170],
  [225, 187],
  [224, 199],
  [241, 175],
  [222, 222],
  [208, 221],
  [210, 233],
  [220, 234],
  [217, 245],
  [204, 245],
  [225, 266],
  [233, 243],
  [236, 214],
  [299, 251],
  [303, 198],
  [277, 167],
  [267, 171],
  [237, 158],
  [250, 161],
  [210, 149],
  [221, 141],
  [232, 130],
  [226, 114],
  [230, 105],
  [238, 120],
  [256, 123],
  [258, 111],
  [253, 105],
  [245, 105],
  [247, 94],
  [256, 98],
  [256, 87],
  [264, 95],
  [272, 93],
  [284, 98],
  [276, 114],
  [274, 123],
  [287, 129],
  [297, 131],
  [295, 109],
  [307, 267],
  [310, 244],
  [312, 220],
  [336, 220],
  [349, 223],
  [348, 204],
  [337, 203],
  [314, 198],
  [314, 171],
  [317, 151],
  [298, 153],
  [294, 141],
  [286, 139],
  [274, 142],
  [309, 109],
  [326, 136],
  [334, 137],
  [341, 124],
  [334, 116],
  [353, 127],
  [349, 140],
  [368, 134],
  [375, 144],
  [388, 143],
  [397, 147],
  [409, 152],
  [423, 159],
  [421, 148],
  [431, 164],
  [437, 167],
  [447, 166],
  [455, 176],
  [482, 193],
  [469, 175],
  [492, 197],
  [496, 170],
  [492, 186],
  [478, 215],
  [462, 198],
  [455, 198],
  [446, 196],
  [443, 187],
  [436, 178],
  [436, 194],
  [424, 190],
  [417, 169],
  [400, 168],
  [379, 166],
  [375, 166],
  [362, 154],
  [354, 153],
  [343, 152],
  [335, 152],
  [330, 151],
  [324, 150],
  [248, 359],
  [312, 326],
  [380, 301],
  [381, 291],
  [381, 278],
  [398, 279],
  [397, 293],
  [414, 279],
  [424, 275],
  [425, 260],
  [416, 259],
  [420, 244],
  [430, 246],
  [436, 259],
  [384, 253],
  [384, 238],
  [407, 188],
  [385, 103],
  [366, 99],
  [352, 112],
  [409, 112],
  [418, 114],
  [413, 95],
  [399, 92],
  [234, 68],
  [238, 50],
  [316, 98],
  [302, 90],
  [286, 82],
  [294, 86],
  [274, 77],
  [255, 72],
  [245, 70],
  [269, 48],
  [286, 51],
  [296, 53],
  [311, 59],
  [328, 68],
  [332, 79],
  [356, 86],
  [342, 101],
  [328, 110],
  [103, 486],
  [88, 478],
  [50, 460],
  [61, 461],
  [36, 448],
  [44, 448],
  [52, 449],
  [60, 450],
  [68, 449],
  [78, 446],
  [88, 446],
  [99, 442],
  [107, 442],
  [107, 456],
  [107, 466],
  [88, 465],
  [40, 436],
  [48, 431],
  [56, 436],
  [56, 423],
  [68, 431],
  [78, 426],
  [88, 420],
  [106, 428],
  [105, 418],
  [110, 410],
  [108, 399],
  [108, 387],
  [109, 373],
  [96, 392],
  [86, 401],
  [74, 412],
  [66, 418],
  [122, 420],
  [121, 408],
  [140, 412],
  [158, 400],
  [160, 391],
  [145, 390],
  [138, 396],
  [130, 396],
  [122, 394],
  [124, 369],
  [131, 370],
  [138, 372],
  [147, 374],
  [152, 354],
  [142, 358],
  [131, 352],
  [125, 356],
  [127, 336],
  [142, 337],
  [153, 333],
  [162, 329],
  [145, 322],
  [134, 324],
  [154, 315],
  [134, 309],
  [136, 300],
  [155, 299],
  [154, 291],
  [146, 292],
  [149, 271],
  [146, 279],
  [172, 273],
  [182, 259],
  [56, 403],
  [64, 395],
  [65, 387],
  [67, 377],
  [69, 369],
  [69, 361],
  [72, 353],
  [74, 344],
  [76, 335],
  [77, 326],
  [79, 319],
  [81, 310],
  [82, 302],
  [84, 293],
  [86, 281],
  [91, 259],
  [84, 257],
  [106, 258],
  [104, 270],
  [104, 280],
  [102, 291],
  [97, 299],
  [94, 313],
  [92, 323],
  [90, 333],
  [88, 344],
  [86, 352],
  [84, 359],
  [83, 368],
  [82, 377],
  [102, 362],
  [106, 333],
  [116, 332],
  [118, 320],
  [120, 314],
  [122, 306],
  [124, 299],
  [126, 291],
  [118, 259],
  [128, 260],
  [137, 260],
  [157, 261],
  [260, 249],
]

export const rust: Array<Point> = [
  [335, 8],
  [325, 71],
  [320, 84],
  [333, 93],
  [368, 94],
  [366, 137],
  [342, 137],
  [303, 140],
  [286, 159],
  [279, 169],
  [272, 178],
  [318, 188],
  [326, 195],
  [317, 196],
  [313, 205],
  [325, 206],
  [311, 218],
  [322, 224],
  [331, 230],
  [312, 225],
  [313, 233],
  [308, 244],
  [318, 244],
  [326, 245],
  [335, 246],
  [344, 250],
  [343, 227],
  [339, 204],
  [349, 212],
  [351, 204],
  [359, 204],
  [370, 210],
  [369, 204],
  [383, 210],
  [383, 218],
  [383, 224],
  [383, 231],
  [418, 263],
  [367, 240],
  [369, 249],
  [349, 258],
  [338, 269],
  [330, 270],
  [323, 270],
  [318, 270],
  [313, 269],
  [306, 270],
  [318, 279],
  [308, 279],
  [301, 279],
  [290, 281],
  [282, 279],
  [284, 268],
  [276, 268],
  [275, 282],
  [266, 266],
  [269, 277],
  [268, 288],
  [266, 298],
  [279, 295],
  [248, 310],
  [238, 302],
  [230, 287],
  [222, 295],
  [240, 265],
  [255, 239],
  [265, 241],
  [283, 233],
  [274, 231],
  [277, 222],
  [267, 203],
  [258, 208],
  [248, 232],
  [275, 192],
  [261, 178],
  [259, 191],
  [251, 198],
  [243, 194],
  [235, 188],
  [228, 188],
  [219, 190],
  [209, 191],
  [231, 166],
  [207, 159],
  [167, 168],
  [157, 164],
  [163, 181],
  [151, 183],
  [157, 193],
  [171, 201],
  [186, 198],
  [183, 216],
  [188, 232],
  [186, 243],
  [186, 252],
  [183, 263],
  [180, 273],
  [176, 284],
  [236, 204],
  [240, 214],
  [226, 196],
  [203, 199],
  [193, 203],
  [238, 226],
  [236, 240],
  [234, 248],
  [231, 256],
  [228, 266],
  [224, 276],
  [204, 277],
  [185, 301],
  [199, 322],
  [205, 333],
  [174, 268],
  [176, 259],
  [166, 255],
  [179, 242],
  [158, 229],
  [148, 234],
  [143, 249],
  [143, 265],
  [167, 306],
  [138, 279],
  [153, 286],
  [131, 288],
  [151, 308],
  [120, 306],
  [147, 321],
  [153, 328],
  [144, 328],
  [140, 335],
  [116, 319],
  [124, 326],
  [138, 342],
  [132, 352],
  [97, 327],
  [259, 485],
  [255, 474],
  [251, 463],
  [246, 448],
  [242, 437],
  [238, 428],
  [236, 420],
  [232, 412],
  [228, 397],
  [220, 387],
  [336, 436],
  [342, 446],
  [234, 376],
  [211, 377],
  [213, 367],
  [223, 364],
  [219, 354],
  [209, 359],
  [207, 353],
  [193, 355],
  [201, 347],
  [210, 341],
  [246, 328],
  [183, 357],
  [178, 363],
  [178, 348],
  [187, 346],
  [195, 340],
  [187, 319],
  [159, 319],
  [140, 353],
  [136, 359],
  [125, 361],
  [119, 368],
  [112, 377],
  [83, 352],
  [103, 385],
  [96, 398],
  [104, 408],
  [90, 407],
  [83, 421],
  [93, 426],
  [78, 443],
]

export const mitterndorf: Array<Point> = [
  [26, 381],
  [77, 309],
  [86, 325],
  [92, 333],
  [99, 346],
  [106, 357],
  [123, 356],
  [116, 344],
  [102, 320],
  [103, 298],
  [113, 296],
  [123, 295],
  [138, 289],
  [152, 348],
  [163, 341],
  [152, 323],
  [165, 317],
  [163, 308],
  [158, 299],
  [150, 288],
  [133, 262],
  [144, 259],
  [156, 258],
  [167, 253],
  [175, 248],
  [178, 265],
  [183, 263],
  [191, 255],
  [184, 242],
  [201, 251],
  [206, 282],
  [216, 272],
  [227, 272],
  [209, 245],
  [192, 235],
  [201, 229],
  [212, 223],
  [224, 216],
  [244, 205],
  [235, 188],
  [267, 196],
  [259, 183],
  [278, 188],
  [293, 185],
  [298, 198],
  [282, 203],
  [272, 208],
  [237, 224],
  [219, 232],
  [290, 265],
  [293, 283],
  [306, 273],
  [281, 245],
  [319, 223],
  [353, 217],
  [362, 201],
  [344, 194],
  [352, 167],
  [359, 169],
  [369, 169],
  [387, 162],
  [396, 158],
  [405, 153],
  [417, 149],
  [430, 139],
  [441, 130],
  [434, 161],
  [423, 165],
  [398, 173],
  [390, 176],
  [374, 206],
  [382, 210],
  [426, 216],
  [414, 240],
  [401, 234],
  [390, 232],
  [353, 255],
  [365, 257],
  [372, 259],
  [382, 261],
  [394, 266],
  [401, 271],
  [411, 272],
  [421, 277],
  [434, 285],
  [318, 174],
  [201, 168],
]

export const michelndorf: Array<Point> = [
  [32, 355],
  [70, 381],
  [62, 327],
  [68, 289],
  [80, 279],
  [97, 273],
  [106, 294],
  [114, 312],
  [71, 319],
  [121, 285],
  [129, 279],
  [137, 275],
  [131, 260],
  [140, 254],
  [146, 251],
  [152, 270],
  [156, 289],
  [153, 307],
  [164, 311],
  [205, 297],
  [201, 281],
  [195, 255],
  [182, 267],
  [171, 253],
  [201, 244],
  [198, 231],
  [211, 224],
  [217, 238],
  [231, 229],
  [244, 220],
  [260, 257],
  [264, 275],
  [280, 270],
  [295, 274],
  [309, 278],
  [325, 284],
  [400, 273],
  [450, 180],
  [454, 195],
  [456, 211],
  [461, 227],
  [440, 234],
  [423, 241],
  [414, 250],
  [403, 237],
  [337, 182],
  [366, 169],
  [332, 171],
  [317, 175],
  [303, 179],
  [285, 181],
  [268, 189],
  [255, 195],
  [244, 203],
  [226, 212],
  [260, 208],
  [273, 222],
  [297, 192],
  [278, 199],
  [245, 150],
  [329, 117],
  [271, 134],
  [316, 188],
  [318, 206],
  [323, 228],
  [338, 268],
  [367, 263],
  [358, 183],
]

export const spital: Array<Point> = [
  [251, 471],
  [238, 474],
  [251, 462],
  [252, 454],
  [248, 440],
  [133, 416],
  [141, 414],
  [148, 416],
  [146, 436],
  [168, 427],
  [158, 431],
  [157, 417],
  [164, 418],
  [176, 402],
  [188, 407],
  [197, 404],
  [201, 404],
  [210, 403],
  [218, 404],
  [226, 406],
  [235, 406],
  [229, 397],
  [230, 412],
  [233, 415],
  [240, 422],
  [239, 431],
  [247, 421],
  [245, 433],
  [255, 414],
  [256, 421],
  [260, 404],
  [248, 402],
  [258, 390],
  [261, 381],
  [266, 394],
  [269, 382],
  [278, 364],
  [268, 369],
  [272, 358],
  [284, 356],
  [273, 337],
  [275, 325],
  [291, 296],
  [292, 259],
  [281, 251],
  [281, 239],
  [294, 233],
  [303, 209],
  [316, 204],
  [302, 226],
  [274, 229],
  [283, 214],
  [291, 201],
  [283, 202],
  [284, 192],
  [284, 183],
  [293, 182],
  [293, 170],
  [284, 170],
  [287, 159],
  [298, 148],
  [298, 130],
  [286, 119],
  [298, 120],
  [310, 101],
  [315, 94],
  [321, 86],
  [330, 75],
  [331, 48],
  [365, 35],
]

export const streithofen: Array<Point> = [
  [93, 104],
  [109, 98],
  [108, 112],
  [129, 102],
  [116, 127],
  [98, 139],
  [150, 112],
  [157, 100],
  [124, 80],
  [97, 75],
  [108, 67],
  [126, 65],
  [125, 51],
  [104, 40],
  [146, 59],
  [147, 73],
  [167, 67],
  [175, 77],
  [175, 94],
  [183, 113],
  [177, 122],
  [167, 132],
  [167, 146],
  [156, 170],
  [150, 184],
  [146, 204],
  [128, 219],
  [102, 272],
  [181, 286],
  [182, 275],
  [180, 261],
  [175, 241],
  [205, 244],
  [201, 225],
  [215, 218],
  [181, 217],
  [177, 201],
  [162, 209],
  [188, 187],
  [188, 176],
  [197, 191],
  [207, 189],
  [226, 191],
  [186, 136],
  [198, 103],
  [219, 84],
  [249, 91],
  [292, 91],
  [309, 89],
  [326, 89],
  [343, 89],
  [369, 99],
  [377, 68],
  [235, 50],
  [207, 64],
  [407, 106],
  [391, 123],
  [340, 102],
  [376, 119],
  [359, 119],
  [369, 137],
  [356, 136],
  [311, 105],
  [301, 106],
  [288, 108],
  [273, 109],
  [261, 109],
  [232, 101],
  [247, 105],
  [218, 98],
  [353, 185],
  [328, 180],
  [329, 159],
  [367, 218],
  [337, 298],
  [361, 307],
  [338, 318],
  [335, 338],
  [339, 363],
  [337, 387],
  [337, 403],
  [334, 414],
  [338, 426],
  [335, 481],
  [281, 177],
]

export const atzelsdorf: Array<Point> = [
  [15, 218],
  [19, 224],
  [20, 228],
  [20, 234],
  [18, 240],
  [20, 246],
  [17, 253],
  [27, 253],
  [28, 232],
  [26, 224],
  [30, 225],
  [34, 245],
  [32, 254],
  [40, 254],
  [36, 238],
  [39, 224],
  [38, 218],
  [53, 200],
  [60, 203],
  [68, 202],
  [52, 228],
  [58, 231],
  [51, 233],
  [50, 239],
  [25, 271],
  [55, 268],
  [62, 266],
  [62, 280],
  [70, 267],
  [65, 256],
  [70, 257],
  [74, 229],
  [80, 230],
  [83, 240],
  [75, 256],
  [82, 256],
  [78, 275],
  [87, 271],
  [88, 301],
  [81, 300],
  [124, 298],
  [127, 297],
  [131, 291],
  [130, 306],
  [136, 299],
  [137, 285],
  [106, 270],
  [93, 257],
  [100, 257],
  [108, 257],
  [109, 244],
  [105, 239],
  [100, 228],
  [110, 232],
  [121, 239],
  [120, 230],
  [129, 231],
  [121, 249],
  [121, 257],
  [132, 254],
  [153, 253],
  [149, 263],
  [156, 261],
  [143, 264],
  [136, 265],
  [129, 265],
  [129, 274],
  [121, 266],
  [164, 262],
  [170, 248],
  [193, 250],
  [201, 249],
  [206, 249],
  [174, 263],
  [182, 262],
  [190, 263],
  [199, 263],
  [208, 263],
  [219, 264],
  [222, 255],
  [229, 250],
  [230, 239],
  [240, 252],
  [224, 262],
  [235, 262],
  [241, 262],
  [251, 260],
  [251, 251],
  [263, 249],
  [274, 247],
  [280, 245],
  [288, 242],
  [279, 253],
  [265, 257],
  [259, 259],
  [286, 255],
  [295, 247],
  [304, 245],
  [310, 244],
  [319, 242],
  [305, 239],
  [297, 240],
  [313, 236],
  [325, 257],
  [329, 246],
  [331, 256],
  [329, 265],
  [332, 272],
  [333, 280],
  [334, 285],
  [340, 289],
  [330, 290],
  [321, 290],
  [330, 240],
  [253, 226],
  [267, 222],
  [301, 200],
  [488, 254],
  [450, 245],
  [449, 256],
  [436, 257],
  [437, 245],
  [432, 244],
  [428, 257],
  [426, 245],
  [420, 244],
  [421, 256],
  [416, 269],
  [417, 279],
  [422, 294],
  [409, 242],
  [402, 241],
  [396, 242],
  [389, 243],
  [385, 242],
  [380, 242],
  [375, 242],
  [367, 240],
  [361, 241],
  [354, 240],
  [345, 240],
  [330, 234],
  [328, 224],
  [322, 211],
  [331, 206],
  [311, 212],
  [299, 214],
  [292, 231],
  [291, 213],
  [351, 208],
  [373, 208],
  [380, 206],
  [388, 205],
  [387, 214],
  [389, 223],
  [389, 231],
  [398, 220],
  [398, 211],
  [373, 234],
  [358, 233],
  [346, 233],
  [338, 233],
  [345, 215],
]

export const pixendorf: Array<Point> = [
  [434, 141],
  [442, 141],
  [448, 141],
  [448, 154],
  [457, 154],
  [449, 167],
  [441, 169],
  [446, 182],
  [461, 182],
  [458, 168],
  [409, 193],
  [385, 190],
  [385, 209],
  [413, 210],
  [403, 274],
  [366, 270],
  [355, 273],
  [345, 269],
  [346, 258],
  [356, 259],
  [365, 261],
  [365, 249],
  [346, 249],
  [346, 237],
  [355, 239],
  [364, 240],
  [366, 230],
  [353, 231],
  [346, 229],
  [348, 218],
  [361, 221],
  [336, 220],
  [335, 236],
  [328, 239],
  [315, 234],
  [315, 219],
  [326, 222],
  [302, 218],
  [302, 222],
  [302, 226],
  [302, 229],
  [302, 232],
  [302, 236],
  [303, 238],
  [303, 241],
  [303, 244],
  [303, 247],
  [297, 255],
  [295, 265],
  [492, 317],
  [486, 328],
  [474, 323],
  [445, 307],
  [429, 308],
  [390, 303],
  [381, 308],
  [374, 309],
  [367, 303],
  [351, 292],
  [341, 290],
  [327, 293],
  [316, 288],
  [304, 285],
  [295, 283],
  [259, 279],
  [261, 286],
  [252, 290],
  [252, 296],
  [250, 305],
  [242, 332],
  [249, 345],
  [202, 285],
  [205, 295],
  [215, 273],
  [201, 271],
  [208, 263],
  [190, 263],
  [208, 254],
  [216, 235],
  [26, 244],
  [13, 263],
  [19, 263],
  [28, 259],
  [38, 260],
  [41, 252],
  [51, 250],
  [65, 257],
  [77, 259],
  [82, 258],
  [89, 259],
  [102, 262],
  [121, 264],
  [142, 263],
  [149, 264],
  [168, 262],
  [176, 261],
  [190, 271],
  [180, 271],
  [170, 271],
  [153, 272],
  [142, 273],
  [130, 274],
  [119, 274],
  [109, 275],
  [102, 288],
  [123, 292],
  [118, 308],
  [108, 313],
  [109, 298],
  [100, 271],
  [82, 280],
  [72, 266],
  [59, 266],
  [47, 269],
  [57, 278],
  [58, 222],
  [55, 204],
  [67, 197],
  [68, 229],
  [93, 223],
  [108, 217],
  [118, 216],
  [121, 231],
  [122, 239],
  [122, 252],
  [129, 248],
  [130, 201],
  [140, 203],
  [148, 202],
  [143, 194],
  [157, 203],
  [170, 201],
  [127, 173],
  [138, 172],
  [127, 159],
  [138, 159],
  [155, 157],
  [158, 170],
  [186, 158],
  [185, 150],
  [185, 181],
  [199, 182],
  [190, 200],
  [198, 199],
  [205, 204],
  [155, 221],
  [167, 221],
  [177, 216],
  [195, 215],
  [188, 226],
  [202, 225],
  [214, 200],
  [213, 178],
  [231, 203],
  [247, 187],
  [248, 214],
  [243, 269],
  [256, 269],
  [274, 270],
  [285, 264],
  [281, 223],
  [302, 208],
  [300, 191],
  [299, 174],
  [298, 163],
  [300, 149],
  [301, 135],
  [311, 159],
  [322, 161],
  [332, 162],
  [340, 163],
  [350, 163],
  [357, 164],
  [369, 165],
  [370, 153],
  [348, 149],
  [340, 147],
  [353, 133],
  [363, 135],
  [372, 135],
  [384, 149],
  [369, 176],
  [361, 180],
  [352, 176],
  [340, 175],
  [331, 178],
  [321, 176],
  [310, 176],
  [313, 192],
  [321, 191],
  [332, 191],
  [315, 204],
  [341, 204],
  [348, 204],
  [366, 206],
  [352, 189],
  [355, 205],
]
