import * as React from 'react'

export const useWebDescription = (description: string) => {
  React.useEffect(() => {
    const metaTagWithWebDescription = document.getElementById('web-description')
    const previousContent = metaTagWithWebDescription?.getAttribute('content')
    metaTagWithWebDescription?.setAttribute('content', description)
    return () => {
      metaTagWithWebDescription?.setAttribute('content', previousContent ?? '')
    }
  }, [description])
}

export const useWebTitle = (title: string) => {
  React.useEffect(() => {
    const previousTitle = document.title
    document.title = title
    return () => {
      document.title = previousTitle
    }
  }, [title])
}
