import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { FileStorage } from './components/pages/FileStorage'
import './styles.css'
import { Spacer } from './components/elementals/Spacer'
import { NavigationButton } from './components/elementals/NavigationButton'
import { NotFound } from './components/pages/NotFound'
import { Path, paths } from './paths'

export const App = () => (
  <>
    <Spacer space={8} style={{ flexWrap: 'wrap' }}>
      {paths
        .filter(([path]) => !directPathBlockList.includes(path))
        .map(([path, label]) => (
          <NavigationButton path={path} key={path} style={{ marginBottom: '0.5em' }}>
            {label}
          </NavigationButton>
        ))}
    </Spacer>
    <Switch>
      {paths.map(([path, _, Component]) => (
        <Route key={path} path={path} exact={path === '/'} component={Component} />
      ))}
      <Route exact path="/filestorage">
        <FileStorage />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </>
)

const directPathBlockList: Array<Path> = [
  '/population_density_experiment',
  '/curriculumVitae',
  '/votePrompts',
  '/games',
  '/bh',
  '/torii',
]
