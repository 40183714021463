import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Spacer } from '../../elementals/Spacer'
import { Page } from '../../elementals/Page'
import { LinkButton } from '../../elementals/Button'

export const Bushaltestellen = () => {
  const currentRoute = useLocation().pathname
  const selectedStation = currentRoute.replace('/bh/', '')

  React.useEffect(() => {
    if (selectedStation !== '') {
      const currentStation = stations.find(([, route]) => route === currentRoute)
      if (currentStation) {
        window.location.href = getStationURL(currentStation[2].stationId)
      }
    }
  }, [selectedStation])

  return (
    <Page>
      <Spacer space={8} style={{ flexWrap: 'wrap' }}>
        {stations.map(([label, route, { stationId }]) => (
          <LinkButton key={route} style={{ marginBottom: '0.5em' }} href={getStationURL(stationId)}>
            {label}
          </LinkButton>
        ))}
      </Spacer>
    </Page>
  )
}

const getStationURL = (stationId: string) =>
  `https://fahrplan.oebb.at/bin/stboard.exe/dn?L=vs_liveticker&evaId=${stationId}&boardType=dep&productsFilter=1011111111011&dirInput=&tickerID=dep&start=yes&eqstops=false&showJourneys=16&additionalTime=0`

type Location = { lat: number; lon: number }
type OEBBInfo = { stationId: string; location: Location }
const stations: Array<[string, string, OEBBInfo]> = [
  [
    'Atzelsdorf am Graben',
    '/bh/graben',
    { stationId: '000321406', location: { lat: 48.287675, lon: 15.950603 } },
  ],
  [
    'Atzelsdorf Kapelle',
    '/bh/kapelle',
    { stationId: '000321218', location: { lat: 48.288021, lon: 15.961498 } },
  ],
  [
    'Pixendorf Feuerwehr',
    '/bh/pxff',
    { stationId: '000321217', location: { lat: 48.287569, lon: 15.976497 } },
  ],
  [
    'Michelhausen Wiener Straße/Spitaler Straße',
    '/bh/michelsdlg',
    { stationId: '321003', location: { lat: 48.285473, lon: 15.935209 } },
  ],
  [
    'Michelhausen Ruster Straße',
    '/bh/michelgewerbe',
    { stationId: '000321382', location: { lat: 48.292819, lon: 15.932498 } },
  ],
  [
    'Michelhausen St.Pöltner Straße',
    '/bh/stpstr',
    { stationId: '000321383', location: { lat: 48.289648, lon: 15.936973 } },
  ],
  [
    'Michelhausen Gemeindeamt',
    '/bh/gemeinde',
    { stationId: '000321220', location: { lat: 48.290148, lon: 15.94077 } },
  ],
  [
    'Michelhausen B1/Abzw Spital',
    '/bh/spital',
    { stationId: '000321002', location: { lat: 48.281992, lon: 15.929333 } },
  ],
  [
    'Michelhausen Bahnhof',
    '/bh/michelbhf',
    { stationId: '321221', location: { lat: 48.296674, lon: 15.934129 } },
  ],
  [
    'Rust Leopold-Figl-Straße',
    '/bh/figlstr',
    { stationId: '321187', location: { lat: 48.304596, lon: 15.928838 } },
  ],
  [
    'Rust Figl-Museum',
    '/bh/figlmuseum',
    { stationId: '321292', location: { lat: 48.302514, lon: 15.929482 } },
  ],
  [
    'Mitterndorf Gewerbestraße',
    '/bh/mitterngewerbe',
    { stationId: '321005', location: { lat: 48.285086, lon: 15.915775 } },
  ],
  [
    'Mitterndorf Steinfeldstraße',
    '/bh/mitternstein',
    { stationId: '321004', location: { lat: 48.285519, lon: 15.916224 } },
  ],
  [
    'Michelndorf West',
    '/bh/micheln',
    { stationId: '321335', location: { lat: 48.279731, lon: 15.892251 } },
  ],
  [
    'Streithofen Bundesstraße',
    '/bh/streit',
    { stationId: '000321001', location: { lat: 48.276644, lon: 15.941206 } },
  ],
  [
    'Bahnhof Tullnerfeld',
    '/bh/tullnerfeld',
    { stationId: '319501', location: { lat: 48.294582, lon: 15.996273 } },
  ],
]

const getDistance = (a: Location, b: Location) => {
  const radius = 6371
  const lat1 = degreesToRadians(a.lat)
  const lat2 = degreesToRadians(b.lat)
  const deltaLat = lat2 - lat1
  const deltaLon = degreesToRadians(b.lon) - degreesToRadians(a.lon)
  const f =
    Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f))
  return radius * c // Haversine distance
}

const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180)
